import { Page } from '@/layouts';
import { AnalysisFooter, AnalysisTopbar } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { selectSteps } from '@/store/campaign/campaign.selector';
import { AnchorTextBatchTable } from './components';
import { MoreAnchorTextModal, MoreAnchorTextActions } from '../AnchorTextResultsPage/components/MoreAnchorTextModal';
import { useToggle } from '@/hooks/toggle';
import { Button, WarningModal } from '@/components';
import { TrashIcon } from '@heroicons/react/24/solid';
import { PlusIcon } from '@heroicons/react/20/solid';
import cn from '@/utils/style';
import { useNavigate, useParams } from 'react-router-dom';
import { useSteps } from '@/hooks/steps';
import { ReadyToReviewModal } from '../AnchorTextResultsPage/components';
import {
  useSaveCampaignConfigMutation,
  useGetAnchorTextBatchesQuery,
  useDuplicateAnchorTextBatchMutation,
  useDeleteAnchorTextBatchMutation,
  useGetCampaignQuery,
  useStartOverAnchorTextBatchMutation,
  useRegenerateUrlAnchorTextsMutation,
} from '@/store/campaign/campaign.api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AppDispatch } from '@/store/store';
import api from '@/store/api';

const DEFAULT_NAME = 'Anchor Text Batch';

const getDefaultBatchName = (id: number) => `${DEFAULT_NAME} ${id}`;

const AnchorTextBatchDashboard = () => {
  const { campaignId } = useParams() as { campaignId: string };
  const dispatch = useDispatch<AppDispatch>();
  const steps = useSelector(selectSteps);
  const navigate = useNavigate();
  const { value: isMoreAnchorTextModalOpen, toggle: toggleMoreAnchorTextModal } = useToggle();
  const { value: isReadyToReviewModalOpen, toggle: toggleReadyToReviewModal } = useToggle();
  const { value: isDeleteBatchesModalOpen, toggle: toggleDeleteBatchesModal } = useToggle();
  const { value: isStartOverConfirmModalOpen, toggle: toggleStartOverConfirmModal } = useToggle({ initialValue: false });
  const { previousStep } = useSteps();

  const [batchesToDelete, setBatchesToDelete] = useState<Record<string, boolean>>({});
  const [duplicateAnchorTextBatch, { isLoading: isDuplicatingBatch }] = useDuplicateAnchorTextBatchMutation();
  const [startOverAnchorTextBatch, { isLoading: isStartingOverBatch }] = useStartOverAnchorTextBatchMutation();
  const [regenerateUrlAnchorTexts, { isLoading: isRegeneratingAnchorTexts }] = useRegenerateUrlAnchorTextsMutation();
  const [deleteBatch] = useDeleteAnchorTextBatchMutation();
  const [deletingBatches, setDeletingBatches] = useState(false);
  const { data: textBatchesList, isLoading: areBatchesLoading } = useGetAnchorTextBatchesQuery({ campaignId, pageSize: 500 });
  const anchorTextBatchesOptions = useMemo(
    () => textBatchesList?.results.map(({ id, title }) => ({ label: title || getDefaultBatchName(id), value: id })) || [],
    [textBatchesList],
  );

  const [pollingInterval, setPollingInterval] = useState(0);
  const { data: campaign } = useGetCampaignQuery({ campaignId }, { pollingInterval });

  const inProgress = useMemo(() => ['in_progress', 'queued'].includes(campaign?.anchors_generation_status || ''), [campaign]);

  useEffect(() => {
    if (inProgress) {
      setPollingInterval(3000);
      return;
    }

    setPollingInterval(0);
    dispatch(api.util.invalidateTags(['anchorTextBatch']));
  }, [inProgress, dispatch]);

  const batchesToDeleteIds = useMemo(() => Object.keys(batchesToDelete).map(Number), [batchesToDelete]);

  const [updateConfig] = useSaveCampaignConfigMutation();

  const handlePrevious = () => navigate(`../${previousStep}`);

  const handleNext = async () => {
    if (!isReadyToReviewModalOpen) {
      return toggleReadyToReviewModal(true);
    }

    await updateConfig({ campaignId, config: { user_progress: { last_step: 'summary' } } });
    navigate('../summary');
  };

  const handleDeleteBatches = useCallback(async () => {
    setDeletingBatches(true);

    const ids = Object.keys(batchesToDelete).map(Number);

    for (const batchId of ids) {
      await deleteBatch({ campaignId, batchId });
    }

    setDeletingBatches(false);

    setBatchesToDelete({});
    if (ids.length === textBatchesList?.results.length) {
      navigate('../anchor-text-generator');
    }
    dispatch(api.util.invalidateTags(['anchorTextBatch']));

    toggleDeleteBatchesModal(false);
  }, [batchesToDelete, campaignId, deleteBatch, toggleDeleteBatchesModal, dispatch, textBatchesList, navigate]);

  const handleCreateNewBatch = useCallback(async () => {
    navigate('../anchor-text-generator');
  }, [navigate]);

  const handleDuplicateBatch = useCallback(
    async (batchId: number, options?: { include_anchors?: boolean }) => {
      const res = await duplicateAnchorTextBatch({ campaignId, batchId, include_anchors: options?.include_anchors }).unwrap();

      const batch = textBatchesList?.results.find((batch) => batch.id === batchId);

      const urls = batch?.urls.map((url) => {
        return {
          id: url.url_id,
          counts: {
            generic: url.generic_match,
            partial: url.partial_match,
            exact: url.exact_match,
            anchors_per_keyword_distribution: [],
          },
        };
      });

      if (!options?.include_anchors && urls) {
        setPollingInterval(3000);
        await regenerateUrlAnchorTexts({ campaign_id: campaignId, urls, batch_id: res.id }).unwrap();
      }
    },
    [campaignId, duplicateAnchorTextBatch, regenerateUrlAnchorTexts, textBatchesList],
  );

  const handleStartOver = useCallback(
    async (batchId: number) => {
      await startOverAnchorTextBatch({ campaignId, batchId });
      navigate(`../anchor-text-generator?batch_id=${batchId}`);
    },
    [campaignId, startOverAnchorTextBatch, navigate],
  );

  const moreAnchorTextActions = useMemo(
    () => ({
      createNew: handleCreateNewBatch,
      duplicateCurrentBatch: handleDuplicateBatch,
      startOver: () => toggleStartOverConfirmModal(true),
    }),
    [handleCreateNewBatch, handleDuplicateBatch, toggleStartOverConfirmModal],
  ) as MoreAnchorTextActions;

  const textBatchesMap = useMemo(
    () =>
      textBatchesList?.results.reduce((acc, batch) => {
        acc[batch.id] = batch.title || getDefaultBatchName(batch.id);
        return acc;
      }, {} as { [key: number]: string }),
    [textBatchesList],
  );

  const getBatchName = (batchId: number | undefined) => batchId && (textBatchesMap?.[batchId] || getDefaultBatchName(batchId));

  return (
    <>
      {isMoreAnchorTextModalOpen && (
        <MoreAnchorTextModal
          actions={moreAnchorTextActions}
          isLoading={isDuplicatingBatch || isStartingOverBatch || isRegeneratingAnchorTexts}
          isOpen={isMoreAnchorTextModalOpen}
          onClose={() => toggleMoreAnchorTextModal(false)}
          selectionEnabled
          shouldActionCloseModal={{
            start_over: false,
          }}
          options={anchorTextBatchesOptions}
          renderChildren={(batch) => (
            <WarningModal
              buttonsLoading={isStartingOverBatch}
              className='w-modal'
              isOpen={isStartOverConfirmModalOpen}
              onClose={() => toggleStartOverConfirmModal(false)}
              onConfirm={async () => {
                batch && batch.start_over && (await handleStartOver(batch?.start_over));
                toggleStartOverConfirmModal(false);
              }}
              message={`Are you sure you want to start over the Anchor Text Generator process for batch ${getBatchName(
                batch?.start_over,
              )}? All progress of the selected Anchor Text batch will be lost`}
            />
          )}
        />
      )}
      {isReadyToReviewModalOpen && <ReadyToReviewModal isOpen={true} onClose={() => toggleReadyToReviewModal(false)} onReview={handleNext} />}
      <WarningModal
        buttonsLoading={deletingBatches}
        isOpen={isDeleteBatchesModalOpen}
        onClose={() => toggleDeleteBatchesModal(false)}
        onConfirm={handleDeleteBatches}
        message={'Are you sure you want to delete the batches? This action is irreversible.'}
      >
        {batchesToDeleteIds.length > 0 && (
          <div className='space-y-2 '>
            <p className='mt-4'>The following batches will be deleted:</p>
            <ul>
              {batchesToDeleteIds.map((batchId) => (
                <li key={batchId}>- {textBatchesMap && textBatchesMap[batchId]}</li>
              ))}
            </ul>
          </div>
        )}
      </WarningModal>
      <Page>
        <AnalysisTopbar onSave={() => {}} isDirty={steps['anchor-text-generator'].isDirty} />
        <Page.Body>
          <div className='flex justify-between'>
            <h2 className='text-left font-semibold'>Anchor Text Generator Overview</h2>
            <div className='flex gap-2'>
              <Button
                isLoading={inProgress}
                onClick={() => toggleDeleteBatchesModal(true)}
                disabled={batchesToDeleteIds.length === 0}
                className='flex items-center gap-2 text-sm text-slate-800'
                variant='outline-light'
              >
                <TrashIcon className={cn('size-5 text-slate-400')} />
                Delete Batches
              </Button>
              <Button isLoading={inProgress} disabled={areBatchesLoading} className='flex items-center gap-2 text-sm' onClick={() => toggleMoreAnchorTextModal(true)}>
                <PlusIcon className={cn('size-5')} />
                More Anchor Text Batches
              </Button>
            </div>
          </div>
          <AnchorTextBatchTable
            isLoading={inProgress || isRegeneratingAnchorTexts || isDuplicatingBatch || isStartingOverBatch}
            withCheckbox
            onCheck={setBatchesToDelete}
            checked={batchesToDelete}
          />
        </Page.Body>
        <Page.Footer>
          <AnalysisFooter onNext={handleNext} onPrevious={handlePrevious} />
        </Page.Footer>
      </Page>
    </>
  );
};

export default AnchorTextBatchDashboard;
