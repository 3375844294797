import { AnchorIcon, RowCountInput, RowPercentageInput } from '@/components';
import cn from '@/utils/style';
import { StarIcon } from '@heroicons/react/24/solid';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<UrlAnchorTextData>();

type UrlAnchorTextTableMeta = {
  setSelectedPriorityUrl: (url: UrlAnchorTextData) => void;
  selectedUrl: UrlAnchorTextData | null;
  setSelectedUrl: (url: UrlAnchorTextData) => void;
  urlAnchorTexts: UrlAnchorText[];
};

const useUrlAnchorTextTableColumns = () => {
  return [
    columnHelper.accessor('links_per_month', {
      header: () => <p className='text-left'># of Links /mo</p>,
      cell: ({ row, getValue }) => {
        return (
          <div>
            <div className='absolute top-2 flex flex-nowrap items-center gap-2 whitespace-nowrap'>
              <p className='font-bold'>{row.original.target_page}</p>
            </div>
            <p className='text-left'>{getValue().toLocaleString()}</p>
          </div>
        );
      },
      size: 80,
    }),
    columnHelper.accessor('exact_match', {
      header: () => <p className='text-left'>Anchor Exact Match</p>,
      cell: ({ row, getValue }) => {
        return (
          <div className='flex gap-2'>
            <RowCountInput disabled initialValue={getValue()} />
            <RowPercentageInput disabled initialValue={getValue()} limit={row.original.links_per_month} />
            <div className='mx-2 flex w-44 flex-col items-start justify-center gap-1 text-xs'>
              <p>Subj: {row.original.cl.exact_match}%</p>
              <p>Comp: {row.original.co.exact_match}%</p>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('partial_match', {
      header: () => <p className='text-left'>Anchor Partial Match</p>,
      cell: ({ row, getValue }) => {
        return (
          <div className='flex gap-2'>
            <RowCountInput disabled initialValue={getValue()} />
            <RowPercentageInput disabled initialValue={getValue()} limit={row.original.links_per_month} />
            <div className='mx-2 flex w-44 flex-col items-start justify-center gap-1 text-xs'>
              <p>Subj: {row.original.cl.partial_match}%</p>
              <p>Comp: {row.original.co.partial_match}%</p>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor('generic_match', {
      header: () => <p className='text-left'>Anchor Generic Match</p>,
      cell: ({ row, getValue }) => {
        return (
          <div className='flex gap-2'>
            <RowCountInput disabled initialValue={getValue()} />
            <RowPercentageInput disabled initialValue={getValue()} limit={row.original.links_per_month} />
            <div className='mx-2 flex w-44 flex-col items-start justify-center gap-1 text-xs'>
              <p>Subj: {row.original.cl.generic_match}%</p>
              <p>Comp: {row.original.co.generic_match}%</p>
            </div>
          </div>
        );
      },
    }),
    columnHelper.display({
      id: 'priority',
      header: () => <p>Priority</p>,
      cell: ({ row, table }) => {
        const { setSelectedPriorityUrl } = table.options.meta as UrlAnchorTextTableMeta;
        const handlePriorityClick = () => setSelectedPriorityUrl && setSelectedPriorityUrl(row.original);

        return (
          <div className='cursor-pointer items-center justify-center opacity-100' onClick={handlePriorityClick}>
            <StarIcon className='mx-auto w-6 text-blue-600' />
          </div>
        );
      },
      size: 1,
    }),
    columnHelper.display({
      id: 'anchor',
      header: () => <p className='text-left'>Anchor Text</p>,
      cell: ({ row, table }) => {
        const { selectedUrl, urlAnchorTexts, setSelectedUrl } = table.options.meta as UrlAnchorTextTableMeta;
        const isSelected = row.original.url_id === selectedUrl?.url_id;
        const hasAnchorTexts = urlAnchorTexts.some((uat) => uat.url.id === row.original.url_id);

        const handleAnchorClick = () => {
          if (!isSelected && hasAnchorTexts) {
            setSelectedUrl(row.original);
          }
        };

        return (
          <div className={cn('flex items-center justify-center', hasAnchorTexts && 'cursor-pointer')} onClick={handleAnchorClick}>
            <AnchorIcon className={cn('w-6 text-gray-200', hasAnchorTexts && 'text-blue-600')} />
          </div>
        );
      },
      size: 1,
    }),
  ];
};

export default useUrlAnchorTextTableColumns;
