import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import appSlice from './app/app.slice';
import authSlice from './auth/auth.slice';
import campaignSlice from './campaign/campaign.slice';
import companySlice from './company/company.slice';
import apiPOC from './poc/poc.api';
import { RootState } from './store';
import { deserializeScenario } from './campaign/campaign.api';
import { BASE_API_URL } from '@/constants/application';

const reducers = {
  api: api.reducer,
  app: appSlice.reducer,
  campaign: campaignSlice.reducer,
  company: companySlice.reducer,
  auth: authSlice.reducer,
  apiPoc: apiPOC.reducer,
};

export const updateCampaignScenarioMutation = createAsyncThunk<Scenario, UpdateScenarioArgs, { state: RootState }>(
  'campaign/updateScenario',
  async ({ campaignId, scenario }, { getState, dispatch }) => {
    try {
      const { company } = getState().company;
      const token = getState().auth.token;

      const response = await fetch(`${BASE_API_URL}campaigns/${campaignId}/scenarios/${scenario.id}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(scenario),
      });

      if (!response.ok) {
        throw new Error('Failed to update scenario');
      }

      const data = await response.json();
      const deserializedData = deserializeScenario(data);

      dispatch(campaignSlice.actions.updateScenario({ scenario: deserializedData, company }));

      return deserializedData;
    } catch (e) {
      throw new Error((e as APIError).data?.message || 'Unknown APIError message.');
    }
  },
);

export default reducers;
