import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ProgressBar, TruncatedTextCell } from '@/components';
import { ArrowPathIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { useRetryURLAudienceMutation } from '@/store/poc/poc.api';
import { useNavigate } from 'react-router-dom';
import cn from '@/utils/style';
import { getLCRMessage, isLCRAvailableToRetry } from '@/utils/lcr';

const columnHelper = createColumnHelper<LCRURL<User>>();

const statusColor = {
  error: 'bg-red-500',
  in_progress: 'bg-yellow-400',
  completed: 'bg-green-500',
  created: 'bg-sky-500',
  queued: 'bg-gray-500',
};

const statusLabel = {
  error: 'Error',
  in_progress: 'In Progress',
  completed: 'Success',
  created: 'Created',
  queued: 'Queued',
};

const useURLsTableColumns = () => {
  const [retryURLAudience] = useRetryURLAudienceMutation();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      columnHelper.accessor('url', {
        header: () => <p className='text-left'>URL</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()}</p>,
      }),
      columnHelper.accessor('offering', {
        header: () => <p className='text-left'>Offering</p>,
        cell: ({ getValue, row, table }) => (
          <TruncatedTextCell row={row} table={table}>
            {getValue()}
          </TruncatedTextCell>
        ),
        size: 400,
      }),
      columnHelper.accessor('buyer_wants_to_achieve', {
        header: () => <p className='text-left'>Want to Achieve</p>,
        cell: ({ getValue, row, table }) => (
          <TruncatedTextCell row={row} table={table}>
            {getValue()}
          </TruncatedTextCell>
        ),
        size: 400,
      }),
      columnHelper.display({
        id: 'status',
        header: () => <p className='text-left'>Status</p>,
        cell: ({
          row: {
            original: { status, message, progress },
          },
        }) => (
          <div className='flex flex-col text-left'>
            <p className='flex items-center gap-2'>
              <span className={cn(statusColor[status], 'h-3 w-3 rounded-full')}></span>
              {statusLabel[status]}
            </p>
            {status !== 'error' && status !== 'completed' ? <ProgressBar progress={progress || 0} showProgress={false} color='bg-green-700' className='my-1' /> : null}
            {status !== 'completed' ? getLCRMessage(status, message) : null}
          </div>
        ),
        size: 175,
      }),
      columnHelper.accessor('user', {
        header: () => <p className='text-left'>Created By</p>,
        cell: ({ getValue }) => <p className='text-left'>{getValue()?.fullName || '-'}</p>,
        size: 125,
      }),
      columnHelper.display({
        id: 'actions',
        header: () => <p className='text-right'>Actions</p>,
        cell: ({ row }) => {
          return (
            <div className='flex w-full justify-end gap-4'>
              {isLCRAvailableToRetry(row.original.status, row.original.message) && (
                <button onClick={() => retryURLAudience({ id: row.original.id })}>
                  <ArrowPathIcon className='size-6 text-gray-400' />
                </button>
              )}
              <button onClick={() => navigate(`url/${row.original.id}/detail`)}>
                <ArrowRightIcon className={cn('size-6 text-gray-400', !row.original.read_at && row.original.status === 'completed' ? ' stroke-current stroke-1' : null)} />
              </button>
            </div>
          );
        },
        size: 75,
      }),
    ],
    [navigate, retryURLAudience],
  );

  return columns;
};

export default useURLsTableColumns;
