import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import CollapsableCard from './CollapsableCard';
import { useCollapsable } from '@/hooks/collapsable';
import { Button, Menu } from '@/components';
import { ArrowsPointingInIcon, ArrowsPointingOutIcon, CheckIcon, ChevronDownIcon, DocumentArrowUpIcon } from '@heroicons/react/24/solid';
import { useExportTargetAudiencesMutation } from '@/store/poc/poc.api';
import { useParams } from 'react-router-dom';
import { FormattedTree, formatTree, getInitialResults, Key, KEY_MAP } from '@/utils/audiencesTree';

interface CollapsableCardsProps {
  audiences: URLTargetAudience[];
  url: string;
}

const AudienceContent: FC<{ audience: URLTargetAudience }> = ({ audience }) => (
  <div className='text-left'>
    <h4 className='mb-2'>{audience.name}</h4>
    <p>{audience.description}</p>
  </div>
);

const PurchaseStakeholderContent: FC<{ purchaseStakeholder: PurchaseStakeholder }> = ({ purchaseStakeholder }) => (
  <div className='w-full text-left'>
    <div className='mb-2 flex items-center justify-between'>
      <h5>Stakeholder: {purchaseStakeholder.name}</h5>
      <div className='flex items-center gap-4 [&>p>span]:text-slate-500'>
        <p>
          <span>Veto Power</span> {purchaseStakeholder.veto_power}
        </p>
        <p>
          <span>Proximity to benefit</span> {purchaseStakeholder.proximity_to_benefit}
        </p>
        <p>
          <span>Importance day-today</span> {purchaseStakeholder.importance_day_to_day}
        </p>
        <p>
          <span>Participate in research</span> {purchaseStakeholder.likelihood_participating_in_research}
        </p>
      </div>
    </div>
    <p>{purchaseStakeholder.description}</p>
  </div>
);

const StakeholderQuestionContent: FC<{ stakeholderQuestion: StakeholderQuestion }> = ({ stakeholderQuestion }) => (
  <div className='text-left'>
    <h5 className='mb-2'>Question: {stakeholderQuestion.question}</h5>
    <div className='flex gap-4'>
      <div className='[&>p]:mb-2 [&>p]:text-slate-500'>
        <p>Tasks</p>
        <p>Next Steps</p>
        <p>Tools</p>
        <p>Techniques</p>
      </div>
      <div className='[&>p]:mb-2'>
        <p>{stakeholderQuestion.tasks.join(', ')}</p>
        <p>{stakeholderQuestion.next_steps.join(', ')}</p>
        <p>{stakeholderQuestion.tools.join(', ')}</p>
        <p>{stakeholderQuestion.techniques.join(', ')}</p>
      </div>
    </div>
  </div>
);

const WorseCaseScenarioCard: FC<{ worseCaseScenario: WorseCaseScenario }> = ({ worseCaseScenario }) => (
  <div className='mt-4 rounded-leap bg-red-100 p-4'>
    <h5 className='mb-2'>{worseCaseScenario.description}</h5>
    <div className='[&>div>p>span]:text-slate-500'>
      <div className='mb-2 flex gap-4'>
        <p className='min-w-[120px]'>
          <span>Feelings</span>
        </p>
        <p>{worseCaseScenario.feelings.join(', ')}</p>
      </div>
      <div className='mb-2 flex gap-4'>
        <p className='min-w-[120px]'>
          <span>How to ease</span>
        </p>
        <p>{worseCaseScenario.how_to_ease}</p>
      </div>
      <div className='mb-2 flex gap-4'>
        <p className='min-w-[120px]'>
          <span>How to accept</span>
        </p>
        <p>{worseCaseScenario.how_to_accept}</p>
      </div>
    </div>
  </div>
);

const ExpertQuestionCard: FC<{ expertQuestion: ExpertQuestion }> = ({ expertQuestion }) => (
  <div className='mt-4 rounded-leap bg-orange-100 p-4'>
    <p>{expertQuestion.question}</p>
  </div>
);

const CollapsableCards: FC<CollapsableCardsProps> = ({ audiences, url }) => {
  const [exportTargetAudiences] = useExportTargetAudiencesMutation();
  const { value, setValue, getValueByKey, toggleByKey } = useCollapsable();
  const [selected, setSelected] = useState<FormattedTree[]>([]);
  const { urlId } = useParams() as { urlId: string };

  const isAllExpand = useMemo(() => {
    const targetAudience = Object.entries(value);

    if (targetAudience.length === 0) {
      return false;
    }

    return targetAudience.filter(([key, _]) => key.startsWith('audience-')).every(([_, value]) => value);
  }, [value]);

  const handleExpandAllTargetAudience = useCallback(() => {
    setValue((prevState) => {
      const newValues: Record<string, boolean> = {};

      Object.keys(prevState).forEach((key) => key.startsWith('audience-') && (newValues[key] = !isAllExpand));

      return { ...prevState, ...newValues };
    });
  }, [setValue, isAllExpand]);

  const isAllSelected = useMemo(() => {
    const checkIsAllSelected = (array: FormattedTree[]): boolean => {
      return array.every((target) => {
        if (target.children) {
          return target.checked && checkIsAllSelected(target.children);
        }

        return target.checked;
      });
    };

    return checkIsAllSelected(selected);
  }, [selected]);

  const handleSelectAll = useCallback(() => {
    const selectAll = (array: FormattedTree[]): FormattedTree[] =>
      array.map((target) => ({ ...target, checked: !isAllSelected, children: target.children ? selectAll(target.children) : null }));

    const newValue = selectAll(selected);

    return setSelected(newValue);
  }, [selected, isAllSelected]);

  const handleExport = useCallback(
    async (target_audiences: number[], purchase_stakeholders?: number[], stakeholder_questions?: number[]) =>
      await exportTargetAudiences({ urlIdOrHash: urlId, url, target_audiences, purchase_stakeholders, stakeholder_questions }),
    [exportTargetAudiences, url, urlId],
  );

  const initialValues = useMemo(
    () =>
      audiences.reduce((accum, current) => {
        accum[`audience-${current.id}`] = false;

        current.purchase_stakeholders.forEach((purchaseStakeholder) => {
          accum[`purchase-stakeholder-${purchaseStakeholder.id}`] = false;

          purchaseStakeholder.stakeholder_questions.forEach((stakeholderQuestion) => {
            accum[`stakeholder-question-${stakeholderQuestion.id}`] = false;
          });
        });

        return accum;
      }, {} as Record<string, boolean>),
    [audiences],
  );

  const selectedData = useMemo(() => {
    const result = getInitialResults();

    const getKey = (deep: number): Key => KEY_MAP[deep];

    const findSelectedChildren = (array: FormattedTree[], deep: number) => {
      for (const element of array) {
        if (element.checked) {
          const key = getKey(deep);
          result[key].push(element.id);
        }
        if (element.children) {
          findSelectedChildren(element.children, deep + 1);
        }
      }
    };

    findSelectedChildren(selected, 0);

    return result;
  }, [selected]);

  const handleCheck = useCallback(
    (id: number) => {
      const selectItems = (array: FormattedTree[], parentIsChecked?: boolean): FormattedTree[] =>
        array.map((target) => {
          const splitedPath = target.path.split('-');

          if (splitedPath.includes(String(id))) {
            const checked = parentIsChecked !== undefined ? parentIsChecked : !target.checked;

            return {
              ...target,
              checked: checked,
              children: target.children ? selectItems(target.children, checked) : null,
            };
          }

          if (target.children) {
            const newValues = {
              ...target,
              children: selectItems(target.children),
            };

            return {
              ...newValues,
              checked: newValues.children?.some((element) => element.checked) || false,
            };
          }

          return target;
        });

      const newValues = selectItems(selected);

      return setSelected(newValues);
    },
    [selected],
  );

  const menuItems = useMemo(() => {
    const { target_audiences, purchase_stakeholders, stakeholder_questions } = selectedData;

    return [
      {
        label: 'Export docx',
        onClick: () => handleExport(target_audiences, purchase_stakeholders, stakeholder_questions),
        icon: <DocumentArrowUpIcon className='size-6 text-slate-400' />,
      },
    ];
  }, [selectedData, handleExport]);

  useEffect(() => {
    setValue(initialValues);
  }, [initialValues, setValue]);

  useEffect(() => {
    const audienceTree = formatTree(audiences, '');
    setSelected(audienceTree);
  }, [audiences]);

  if (Object.entries(value).length === 0) {
    return null;
  }

  return (
    <div>
      <div className='flex items-center justify-end gap-1 [&>span]:h-6 [&>span]:w-[1px] [&>span]:bg-slate-300'>
        <Button
          onClick={handleExpandAllTargetAudience}
          className='flex items-center gap-2 bg-transparent text-slate-800 hover:bg-transparent hover:text-slate-600 active:bg-transparent'
        >
          {isAllExpand ? (
            <Fragment>
              <ArrowsPointingInIcon className='size-6' /> Collapse All
            </Fragment>
          ) : (
            <Fragment>
              <ArrowsPointingOutIcon className='size-6' /> Expand All
            </Fragment>
          )}
        </Button>
        <span></span>
        <Button className='flex items-center gap-2 bg-transparent text-slate-800 hover:bg-transparent hover:text-slate-600 active:bg-transparent' onClick={handleSelectAll}>
          <CheckIcon className='size-6' /> {isAllSelected ? 'Deselect All' : 'Select All'}
        </Button>
        <span></span>
        <Menu
          items={menuItems}
          direction='left'
          className='z-20 text-slate-800'
          menuDisabled={Object.values(selectedData).every((value) => value.length === 0)}
          menuIcon={
            <div className='flex items-center gap-1 px-6 py-2 font-medium'>
              Actions <ChevronDownIcon className='size-4' />
            </div>
          }
        />
      </div>
      {audiences.map((audience) => (
        <CollapsableCard
          key={`audience-${audience.id}`}
          content={<AudienceContent audience={audience} />}
          onCheck={() => handleCheck(audience.id)}
          checked={selectedData['target_audiences'].includes(audience.id)}
          containerClass='bg-white'
          isOpen={getValueByKey(`audience-${audience.id}`)}
          onToggle={() => toggleByKey(`audience-${audience.id}`)}
          isLoading={audience.status === 'new'}
          extraButtons={[{ icon: <DocumentArrowUpIcon className='size-6 text-slate-500' />, onClick: () => handleExport([audience.id]) }]}
        >
          {audience.purchase_stakeholders?.map((purchaseStakeholder) => (
            <CollapsableCard
              key={`purchase-stakeholder-${purchaseStakeholder.id}`}
              content={<PurchaseStakeholderContent purchaseStakeholder={purchaseStakeholder} />}
              containerClass='bg-sky-100'
              isOpen={getValueByKey(`purchase-stakeholder-${purchaseStakeholder.id}`)}
              onToggle={() => toggleByKey(`purchase-stakeholder-${purchaseStakeholder.id}`)}
              onCheck={() => handleCheck(purchaseStakeholder.id)}
              checked={selectedData['purchase_stakeholders'].includes(purchaseStakeholder.id)}
            >
              {purchaseStakeholder.stakeholder_questions.map((stakeholderQuestion) => (
                <CollapsableCard
                  key={`stakeholder-question-${stakeholderQuestion.id}`}
                  content={<StakeholderQuestionContent stakeholderQuestion={stakeholderQuestion} />}
                  containerClass='bg-white'
                  isOpen={getValueByKey(`stakeholder-question-${stakeholderQuestion.id}`)}
                  onToggle={() => toggleByKey(`stakeholder-question-${stakeholderQuestion.id}`)}
                  onCheck={() => handleCheck(stakeholderQuestion.id)}
                  checked={selectedData['stakeholder_questions'].includes(stakeholderQuestion.id)}
                >
                  <div className='mt-6 text-left'>
                    <h5>Worse Case Scenarios</h5>
                    {stakeholderQuestion.worse_case_scenarios.map((worseCaseScenario) => (
                      <WorseCaseScenarioCard worseCaseScenario={worseCaseScenario} key={`worse-case-scenario-${worseCaseScenario.id}`} />
                    ))}
                  </div>
                  <div className='mt-6 text-left'>
                    <h5 className='mb-2'>Expert {stakeholderQuestion.expert_questions.at(0)?.expert.name} Questions</h5>
                    <p className='text-slate-500'>{stakeholderQuestion.expert_questions.at(0)?.expert.description}</p>
                    {stakeholderQuestion.expert_questions?.map((expertQuestion) => (
                      <ExpertQuestionCard expertQuestion={expertQuestion} key={`expert-question-${expertQuestion.id}`} />
                    ))}
                  </div>
                </CollapsableCard>
              ))}
            </CollapsableCard>
          ))}
        </CollapsableCard>
      ))}
    </div>
  );
};

export default CollapsableCards;
