import cn from '@/utils/style';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

type BreadcrumbItem = {
  label: string;
  url?: string;
  disabled?: boolean;
};

const Breadcrumbs = ({ items }: { items: BreadcrumbItem[] }) => {
  const renderBreadcrumbItem = (item: BreadcrumbItem, index: number) => {
    return (
      <div className='flex items-center gap-2 text-sm' key={item.label}>
        {item.url && !item.disabled ? (
          <Link className='text-slate-800' to={item.url}>
            {item.label}
          </Link>
        ) : (
          <span className={cn('text-slate-400', item.disabled && 'cursor-not-allowed')}>{item.label}</span>
        )}
        {index !== items.length - 1 && <ChevronRightIcon className='size-5 text-slate-400' />}
      </div>
    );
  };
  return <div className='mb-6 flex items-center gap-2 text-sm'>{items.map(renderBreadcrumbItem)}</div>;
};

export default Breadcrumbs;
