import { round } from '@/utils/numbers';
import { CHART_COLORS } from '@/utils/table';
import { FC, useMemo } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
interface AnchorTextOverviewGraphProps {
  results: AnchorTextTotal;
}

const createGetMatchPercentage = (total: number) => (matchCount: number) => round((matchCount * 100) / total, 2);

const AnchorTextOverviewGraph: FC<AnchorTextOverviewGraphProps> = ({ results }) => {
  const parsedData = useMemo(() => {
    const { exact, generic, total, partial } = results;

    const getMatchPercentage = createGetMatchPercentage(total);

    return [
      { name: 'exact', value: getMatchPercentage(exact) },
      { name: 'partial', value: getMatchPercentage(partial) },
      { name: 'generic', value: getMatchPercentage(generic) },
    ];
  }, [results]);

  const renderBreakdown = (item: typeof parsedData[number], index: number) => {
    return (
      <div key={index} className='flex items-center justify-between border-b border-b-slate-300 py-1 last-of-type:border-b-transparent'>
        <div className='flex items-center gap-2 py-1'>
          <div className='mr-2 h-4 w-4 rounded-full' style={{ backgroundColor: CHART_COLORS[index] }} />
          <b className='capitalize'>{item.name}</b>
        </div>
        <p>{item.value}%</p>
      </div>
    );
  };

  return (
    <div className='flex w-full items-center justify-center gap-6 rounded-leap bg-white p-5 text-left text-gray-800 shadow-md'>
      <ResponsiveContainer className='max-w-[135px]' height={135}>
        <PieChart>
          <Pie data={parsedData} innerRadius={48} outerRadius={68} paddingAngle={5} dataKey='value'>
            {parsedData.map((_, index) => (
              <Cell key={`cell-${index}`} fill={CHART_COLORS[index]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className='flex w-full flex-col gap-5'>
        <p className='text-xl leading-8'>Match Breakdown</p>
        <div>{parsedData.map(renderBreakdown)}</div>
      </div>
    </div>
  );
};

export default AnchorTextOverviewGraph;
