import { Modal, ModalProps, PriorityLegend, SearchBarDebounce, VirtualizedTable } from '@/components';
import ScenarioKeywordsModalFooter from './ScenarioKeywordsModalFooter';
import { useTable } from '@/hooks/table';
import { selectTablesConfig } from '@/store/app/app.selector';
import { useGetCampaignConfigQuery, useGetRefineKeywordsQuery, useRefineCampaignKeywordsMutation, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import { FilterFnOption, Row } from '@tanstack/react-table';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useColumns from './columns';
import cn from '@/utils/style';
import { getScenariosKeywordTableData } from '@/utils/scenarios';

type ScenarioKeywordsModal = ModalProps & {
  keywordsAllocations: AllocationKeyword[];
  setKeywordsAllocations: Dispatch<SetStateAction<AllocationKeyword[]>>;
  userSelectionMap: Map<number, KeywordAllocation>;
  urlId?: number;
  maxAnchors?: number;
  allowAllocation?: boolean;
  allowZeroTotalAnchors?: boolean;
  allowPriorityChange?: boolean;
};

const campaignKeywordGlobalFilter = (row: Row<AllocationKeyword>, _columnId: keyof AllocationKeyword, filterValue: string) => {
  return row.original.keyword.includes(filterValue) || row.original.targetPage.includes(filterValue);
};

const ScenarioKeywordsModal: FC<ScenarioKeywordsModal> = ({
  keywordsAllocations,
  setKeywordsAllocations,
  userSelectionMap,
  onClose,
  onConfirm,
  isLoading,
  urlId = undefined,
  maxAnchors = 0,
  allowAllocation = true,
  allowZeroTotalAnchors = false,
  allowPriorityChange = true,
}) => {
  const { campaignId } = useParams() as { campaignId: string };
  const dispatch = useDispatch<AppDispatch>();
  const tableConfig = useSelector(selectTablesConfig);
  const { data } = useGetCampaignConfigQuery({ campaignId });
  const { data: keywordsData, isLoading: isKeywordsDataLoading } = useGetRefineKeywordsQuery({ campaignId, url_id: urlId ? String(urlId) : undefined });
  const [refine] = useRefineCampaignKeywordsMutation();
  const [updateConfig] = useSaveCampaignConfigMutation();

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const tableData = useMemo(() => getScenariosKeywordTableData(keywordsData?.results || [], keywordsAllocations), [keywordsAllocations, keywordsData]);

  const columns = useColumns(allowAllocation, allowZeroTotalAnchors, allowPriorityChange);

  const addKeywordToAllocate = useCallback(() => {
    if (!keywordsData) {
      return;
    }

    const newKws = keywordsData.results.filter((kw) => {
      return !keywordsAllocations.find((ka) => ka.id === kw.id);
    });

    if (!newKws.length) {
      return;
    }

    const transformedKws: AllocationKeyword[] = newKws.map((kw) => ({
      id: kw.id,
      keyword: kw.text,
      targetPage: kw.target_page,
      totalVolume: kw.search_volume || 0,
      difficulty: kw.difficulty,
      rank: kw.campaign_rank,
      lrdGapKeyword: 0,
      priority: 0,
      anchorsTotal: 0,
      text_hash: kw.text_hash,
      rootDomainDiff: kw.rootDomainDiff,
      is_empty_data: kw.is_empty_data,
      excluded: kw.excluded,
    }));

    setKeywordsAllocations((old) => [...old, ...transformedKws]);
  }, [keywordsAllocations, keywordsData, setKeywordsAllocations]);

  useEffect(() => {
    addKeywordToAllocate();
  }, [addKeywordToAllocate]);

  const [table] = useTable({
    data: tableData,
    columns,
    tableCustomOptions: ['allowSort', 'allowFilters'],
    defaultSorting: tableConfig['keywordsAllocation'].sorting || [{ id: 'totalVolume', desc: true }],
    tableOptions: {
      onGlobalFilterChange: setGlobalFilterValue,
      globalFilterFn: campaignKeywordGlobalFilter as unknown as FilterFnOption<ScenarioRefineKeyword>,
      state: {
        globalFilter: globalFilterValue,
      },
      meta: {
        updatePriority: ({ keywordId, priority }: { keywordId: number; priority: number }) => {
          setKeywordsAllocations((old) => old.map((kw) => (kw.id === keywordId ? { ...kw, priority } : kw)));
        },
        updateEveryPriority: (priority: number) => {
          setKeywordsAllocations((old) => old.map((kw) => ({ ...kw, priority })));
        },
        updateAllocation: ({ keywordId, anchorsTotal }: { keywordId: number; anchorsTotal: number }) => {
          setKeywordsAllocations((old) => old.map((kw) => (kw.id === keywordId ? { ...kw, anchorsTotal } : kw)));
        },
      },
    },
  });

  const totalAnchors = useMemo(() => {
    return keywordsAllocations.reduce((acc, k) => {
      if (k.priority === 0) {
        return acc;
      }

      const userValue = k.anchorsTotal;
      if (userValue || userValue === 0) {
        return acc + userValue;
      }

      const configValue = userSelectionMap.get(k.id)?.anchorsTotal;
      if (configValue || configValue === 0) {
        return acc + configValue;
      }

      return acc + (k.anchorsTotal || 0);
    }, 0);
  }, [keywordsAllocations, userSelectionMap]);

  const errorMessage = useMemo(() => {
    if (totalAnchors === maxAnchors || !allowAllocation) {
      return '';
    }

    if (totalAnchors > maxAnchors) {
      return `You can set up to ${maxAnchors.toLocaleString()} Allocations`;
    }

    return 'You must allocate all anchors before continuing';
  }, [allowAllocation, maxAnchors, totalAnchors]);

  const handleConfirmKws = async () => {
    setIsSubmitting(true);
    if (totalAnchors > maxAnchors) {
      setIsSubmitting(false);
      return false;
    }

    const refineKeywords = keywordsAllocations.map((kw) => ({
      id: kw.id,
      priority: kw.priority,
      text: kw.keyword,
      anchorsTotal: kw.anchorsTotal,
      excluded: kw.priority === 0,
      text_hash: kw.text_hash,
    }));

    await refine({ campaignId, keywords: refineKeywords }).unwrap();

    const configKws = (data?.data.keywords as AllocationKeyword[]) || [];

    const newKws = Object.values(
      [...configKws, ...keywordsAllocations].reduce((acc, kw) => {
        acc[kw.id] = kw;
        return acc;
      }, {} as Record<number, AllocationKeyword>),
    );

    await updateConfig({
      campaignId,
      config: { keywords: newKws },
    }).unwrap();

    dispatch(setIsDirty({ step: 'anchor-text-generator', isDirty: true }));
    onConfirm && onConfirm();
    setIsSubmitting(false);
  };

  const allKeywordsAllocated = useMemo(() => totalAnchors !== maxAnchors || totalAnchors === 0, [maxAnchors, totalAnchors]);

  return (
    <Modal
      isOpen
      title='Priority Keywords'
      className='w-modal-2xl'
      footer={<ScenarioKeywordsModalFooter disabled={allKeywordsAllocated} isSubmitting={isSubmitting} onClose={onClose} onConfirm={handleConfirmKws} />}
      errorMessage={allowAllocation ? errorMessage : undefined}
    >
      <div className='mb-4'>
        <SearchBarDebounce onChange={(value) => setGlobalFilterValue(value)} inputClassName='w-full' />
      </div>
      <div>
        <div className='flex items-center justify-between'>
          <PriorityLegend
            message={allowAllocation ? `You have set ${totalAnchors.toLocaleString()} / ${maxAnchors.toLocaleString()} allocations` : undefined}
            messageClass={cn(totalAnchors > maxAnchors ? 'text-red-500' : 'text-green-500', 'ml-auto')}
          />
        </div>
        <VirtualizedTable table={table} name='keywordsAllocation' itemHeight={45} isLoading={isLoading || isKeywordsDataLoading} />
      </div>
    </Modal>
  );
};

export default ScenarioKeywordsModal;
