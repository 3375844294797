import { ChartTooltipContent, Checkbox, ColumnBarChart, ColumnPieChart, SERPFeaturesCell, Tooltip, TrendCell } from '@/components';
import { round } from '@/utils/numbers';
import cn from '@/utils/style';
import { FilterFnOption, Row, Table, TableMeta, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { AnchorTextTooltipContent, BacklinkDADistributionTooltipContent, VelocityTooltipContent } from '../../../AnalysisPage/components/AnchorTextTooltipContent';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
const columnHelper = createColumnHelper<AnalysisDataWithFailedUrls>();

const stringFilter = 'stringFilter' as FilterFnOption<AnalysisData>;
const numberFilter = 'numberFilter' as FilterFnOption<AnalysisData>;

interface KeywordAnalysisTableMeta extends TableMeta<AnalysisDataWithFailedUrls> {
  onCheck: (keywords: Keyword[]) => Promise<void>;
  toggleModal: (newVal?: boolean) => void;
  maxGapRatio: number;
}

const handleOnCheck = async ({ row, table }: { row: Row<AnalysisData>; table: Table<AnalysisData> }) => {
  const isCurrentRowSelected = row.getIsSelected();
  const selectedRows = table.getCoreRowModel().flatRows.filter((row) => row.getIsSelected());

  if (selectedRows.length - 1 === 0 && isCurrentRowSelected) {
    return (table.options.meta as KeywordAnalysisTableMeta).toggleModal(true);
  }

  const toggledKw = { ...row.original.keyword, excluded: isCurrentRowSelected };
  await (table.options.meta as KeywordAnalysisTableMeta).onCheck([toggledKw]);
  row.toggleSelected(!isCurrentRowSelected);
};

const handleOnCheckAll = async ({ table }: { table: Table<AnalysisData> }) => {
  const allSelected = table.getIsAllRowsSelected();

  if (allSelected) {
    return (table.options.meta as KeywordAnalysisTableMeta).toggleModal(true);
  }

  const rows = table.getRowModel().flatRows;
  const toggledKws = table.getIsAllRowsSelected() ? rows.map((r) => ({ ...r.original.keyword, excluded: true })) : rows.map((r) => ({ ...r.original.keyword, excluded: false }));
  await (table.options.meta as KeywordAnalysisTableMeta).onCheck(toggledKws);
  table.toggleAllRowsSelected(!allSelected);
};

const useKeywordAnalysisColumns = () => {
  const columns = useMemo(
    () => [
      columnHelper.accessor('closeToGap', {
        header: ({ table }) => <Checkbox checked={table.getIsAllRowsSelected()} onChange={() => handleOnCheckAll({ table })} />,
        cell: ({ row, table }) => <Checkbox checked={row.getIsSelected()} onChange={() => handleOnCheck({ row, table })} />,
        size: 45,
        enableSorting: false,
      }),
      columnHelper.accessor('keyword', {
        size: 80,
        header: () => <p className='whitespace-nowrap text-right'>Include Keyword</p>,
        cell: ({ getValue, row }) => {
          const failed = row.original.isFailed;
          const reasons = row.original.message;

          const renderReasons = (reason: string) => <p key={reason}>{reason}</p>;
          return (
            <div className='grid grid-flow-col grid-rows-1 justify-start gap-2'>
              <span>{getValue().text}</span>
              {failed && (
                <Tooltip content={<div>{reasons?.split(',').map(renderReasons)}</div>} id={`${row.original.url}-${row.original.keyword.id}`} clickable={false}>
                  <ExclamationTriangleIcon className=' size-5 text-red-500' />
                </Tooltip>
              )}
            </div>
          );
        },
        filterFn: stringFilter,
      }),
      columnHelper.accessor('totalVolume', {
        size: 1,
        header: () => (
          <p className='text-right'>
            Search <br /> Volume
          </p>
        ),
        cell: ({ getValue, row }) => {
          return <p className='text-right'>{row.original.isFailed ? 'N/A' : getValue().toLocaleString()}</p>;
        },
        filterFn: numberFilter,
      }),
      columnHelper.accessor('difficulty', {
        size: 1,
        header: () => <p className='text-right'>Difficulty (SEMR)</p>,
        cell: ({ getValue, row }) => <p className='text-right'>{row.original.isFailed ? 'N/A' : round(getValue() * 100, 2) + '%'}</p>,
        filterFn: numberFilter,
      }),
      columnHelper.accessor('campaignRank', {
        header: () => <p className='whitespace-nowrap text-right'>Avg Rank</p>,
        cell: ({ getValue, row }) => <p className='text-right'>{row.original.isFailed ? 'N/A' : round(getValue(), 2)}</p>,
        filterFn: numberFilter,
      }),
      columnHelper.accessor('pageAuthorityDiff', {
        size: 100,
        header: () => (
          <p className='text-right'>
            Page <br /> Authority
          </p>
        ),
        cell: ({ row: { original, id } }) => {
          const subjectPA = original.pageAuthority;
          const competitorPA = original.competitor.pageAuthority;

          const tooltipContent = {
            subject: subjectPA,
            competitor: competitorPA,
          };
          if (original.isFailed) {
            return <p className='text-right'>N/A</p>;
          }

          return (
            <Tooltip id={`${id}-pa-diff`} clickable={false} place='right' content={<ChartTooltipContent hidePercentage={true} data={tooltipContent} title='Page Authority' />}>
              <TrendCell className='' value={round(subjectPA - competitorPA, 2)} />
            </Tooltip>
          );
        },
        filterFn: numberFilter,
      }),

      columnHelper.accessor('linksRootDomainDiff', {
        size: 125,
        header: () => (
          <p className='text-right'>
            Links / <br /> Domain
          </p>
        ),
        cell: ({ row: { id, original } }) => {
          const subjectLRD = round(original.linksRootDomain, 2);
          const competitorLRD = round(original.competitor.linksRootDomain, 2);

          const tooltipContent = {
            subject: subjectLRD,
            competitor: competitorLRD,
          };

          if (original.isFailed) {
            return <p className='text-right'>N/A</p>;
          }

          return (
            <Tooltip id={`${id}-lrd`} clickable={false} place='right' content={<ChartTooltipContent hidePercentage={true} data={tooltipContent} title='Links / RD Diff' />}>
              <TrendCell className='' value={round(competitorLRD - subjectLRD, 0)} />
            </Tooltip>
          );
        },
        filterFn: numberFilter,
      }),
      columnHelper.accessor('lrdGap', {
        size: 75,
        header: () => (
          <p className='text-right'>
            Domain <br /> Gap
          </p>
        ),
        cell: ({ row: { id, original } }) => {
          const tooltipContent = {
            subject: original.rootDomain,
            competitor: original.competitor.rootDomain,
          };

          return (
            <>
              <Tooltip id={`${id}-lrd-gap`} clickable={false} place='right' content={<ChartTooltipContent hidePercentage={true} data={tooltipContent} title='Root Domain' />}>
                <p className={cn('text-right', original.lrdGap > 0 && 'text-red-500')}>{original.lrdGap > 0 ? Math.ceil(original.lrdGap).toLocaleString() : '-'}</p>
              </Tooltip>
            </>
          );
        },
        filterFn: numberFilter,
      }),

      columnHelper.accessor('volumeGapRatio', {
        size: 60,
        header: () => (
          <p className='text-right'>
            Gap <br /> Index
          </p>
        ),
        cell: ({ getValue, row, table }) => {
          if (row.original.competitor.rootDomain - row.original.rootDomain <= 0) {
            return <p className='text-right'>-</p>;
          }

          const maxGapRatio = (table.options.meta as KeywordAnalysisTableMeta).maxGapRatio;

          const gapIndex = (getValue() / maxGapRatio) * 100;
          const roundedGapIndex = Math.ceil(gapIndex);
          return <p className='text-right'>{roundedGapIndex}</p>;
        },
      }),

      columnHelper.accessor('backlinkDADistribution', {
        header: () => <p className='text-left'>Backlink DA Distro</p>,
        cell: ({ getValue, row: { original, id } }) => {
          if (original.isFailed) {
            return <p className='text-left'>N/A</p>;
          }

          const subjectValue = getValue();
          const competitorValue = original.competitor.backlinkDADistribution;

          return (
            <div className='flex items-center'>
              <Tooltip
                id={`${id}-${'subjectBacklinkDADistribution'}`}
                clickable={false}
                place='right'
                content={<BacklinkDADistributionTooltipContent subject={subjectValue} competitor={competitorValue} title='Backlink Domain Authority Distribution' />}
              >
                <div className='flex gap-2'>
                  <div className='flex flex-col text-left'>
                    <p>Subj</p>
                    <p>Comp</p>
                  </div>
                  <div className='flex w-[80px] flex-col'>
                    <ColumnBarChart data={subjectValue} className='w-full' height={6} />
                    <ColumnBarChart data={competitorValue} className='w-full' height={6} />
                  </div>
                </div>
              </Tooltip>
            </div>
          );
        },
        enableSorting: false,
      }),

      columnHelper.accessor('ageAverage', {
        size: 30,
        header: () => (
          <p className='whitespace-nowrap text-center'>
            Link Age <br /> (Avg)
          </p>
        ),
        cell: ({ row: { id, original } }) => {
          const tooltipContent = {
            subject: original.ageAverage,
            competitor: original.competitor.ageAverage,
          };

          const value = Math.ceil(original.ageAverage - original.competitor.ageAverage);

          if (original.isFailed) {
            return <p className='text-right'>N/A</p>;
          }

          return (
            <Tooltip id={`${id}-age-avg`} clickable={false} place='right' content={<ChartTooltipContent hidePercentage={true} data={tooltipContent} title='Link Age' />}>
              <p className={cn('', 'text-right text-gray-500', value > 0 && 'text-red-500', value < 0 && 'text-green-500')}>{Math.abs(value).toLocaleString()}</p>
            </Tooltip>
          );
        },
        filterFn: numberFilter,
      }),

      columnHelper.accessor('velocityDiff.6months', {
        size: 80,
        header: () => (
          <p className='whitespace-nowrap text-right'>
            Velocity <br /> Diff.
          </p>
        ),
        cell: ({ getValue, row }) => {
          if (row.original.isFailed) {
            return <p className='text-right'>N/A</p>;
          }

          return (
            <Tooltip
              id={`${row.original.keyword.text_hash}-${'velocity'}`}
              clickable={false}
              place='left'
              content={<VelocityTooltipContent client={row.original.velocity} competitor={row.original.competitor.velocity} title='Velocity' />}
            >
              <p className={cn('text-right text-gray-500', getValue() > 0 && 'text-green-500', getValue() < 0 && 'text-red-500')}>
                {Math.abs(round(getValue(), 0)).toLocaleString()}
              </p>
            </Tooltip>
          );
        },
      }),

      columnHelper.accessor('contextRelevanceScoreAvg', {
        size: 1,
        header: () => <p className='text-right'>Link Relevance</p>,
        cell: ({ row }) => {
          if (row.original.isFailed) {
            return <p className='text-right'>N/A</p>;
          }

          const subjectCRS = round(row.original.contextRelevanceScoreAvg, 2);
          const competitorCRS = round(row.original.competitor.contextRelevanceScoreAvg, 2);

          const tooltipContent = {
            subject: subjectCRS,
            competitor: competitorCRS,
          };

          return (
            <Tooltip
              id={`${row.id}-crs-diff`}
              clickable={false}
              place='left'
              content={<ChartTooltipContent hidePercentage={true} data={tooltipContent} title='Link Relevance' countToScore={true} />}
            >
              <p className={cn('text-right text-gray-700', subjectCRS > competitorCRS && 'text-green-500', competitorCRS > subjectCRS && 'text-red-500')}>
                {Math.abs(round(subjectCRS - competitorCRS, 2)).toLocaleString()}
              </p>
            </Tooltip>
          );
        },
      }),
      columnHelper.accessor('serpFeaturesByKeyword', {
        header: () => {
          return <p className='text-left'>SERP Features</p>;
        },
        cell: ({ row }) => <SERPFeaturesCell row={row} />,
        enableSorting: false,
      }),

      columnHelper.accessor('anchorTextBuckets', {
        size: 130,
        header: () => <p className='text-left'>Anchor Text Buckets</p>,
        cell: ({ getValue, row: { id, original } }) => {
          const value = getValue();
          const data = Object.keys(value).map((key) => ({
            name: key,
            value: value[key as keyof AnchorTextBuckets],
          }));

          const subjectTotal = Object.values(value).reduce((total, val) => total + val, 0);
          const competitorTotal = Object.values(original.competitor.anchorTextBuckets).reduce((total, val) => total + val, 0);

          const tooltipData = {
            subject: value,
            competitor: original.competitor.anchorTextBuckets,
            subjectTotal: subjectTotal,
            competitorTotal: competitorTotal,
          };

          const renderChartDetail = (key: string, index: number) => {
            if (key === 'other') {
              return;
            }
            const subject = subjectTotal ? (value[key as keyof AnchorTextBuckets] * 100) / subjectTotal : 0;
            const competitor = competitorTotal ? (original.competitor.anchorTextBuckets[key as keyof AnchorTextBuckets] * 100) / competitorTotal : 0;

            const titles: Record<string, string> = {
              exactMatch: 'Exact',
              partialMatch: 'Partial',
              // other: 'Other',
            };

            return (
              <p key={index} className={cn('text-gray-700', subject > competitor && 'text-red-500', competitor > subject && 'text-green-500')}>
                {titles[key]}: {`${Math.abs(round(subject - competitor, 2))}%`}
              </p>
            );
          };

          return (
            <div className='w-40'>
              <Tooltip id={`${id}-anchor_text_buckets`} clickable={false} place='left' content={<AnchorTextTooltipContent title='Anchor Text Buckets' data={tooltipData} />}>
                <ColumnPieChart data={data}>
                  <div className='whitespace-nowrap text-left'>{Object.keys(value).map(renderChartDetail)}</div>
                </ColumnPieChart>
              </Tooltip>
            </div>
          );
        },
        enableSorting: false,
      }),
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return columns;
};

export default useKeywordAnalysisColumns;
