import { Skeleton, VirtualizedTable } from '@/components';
import { useTable } from '@/hooks/table';
import { useGetCampaignConfigQuery } from '@/store/campaign/campaign.api';
import { selectCampaignAnalysis } from '@/store/campaign/campaign.selector';
import { getScenarioUrlKeywords, getUserSelectionAllocations } from '@/utils/campaigns';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ScenarioKeywordsModal } from '../../../AnchorTextGeneratorPage';
import AnchorTextDrawer from '../AnchorTextDrawer';
import useUrlAnchorTextTableColumns from './useUrlAnchorTextTableColumns';

type UrlAnchorTextTableProps = {
  urlAnchorTexts: UrlAnchorText[];
  updateUrlAnchorTexts: (url: UrlAnchorText) => void;
  urlAnchorsTextData: UrlAnchorTextData[];
  isLoading: boolean;
  scenario?: Scenario;
};

const LoadingTableRow = () => {
  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-2 border-b border-gray-100 pb-4 pt-2'>
        <Skeleton className='h-8 w-1/4' />
        <Skeleton className='h-8 w-full' />
      </div>
      <div className='flex flex-col gap-2 border-b border-gray-100 pb-4 pt-2'>
        <Skeleton className='h-8 w-1/4' />
        <Skeleton className='h-8 w-full' />
      </div>
      <div className='flex flex-col gap-2 pb-2 pt-4'>
        <Skeleton className='h-8 w-1/4' />
        <Skeleton className='h-8 w-full' />
      </div>
    </div>
  );
};

const UrlAnchorTextTable: FC<UrlAnchorTextTableProps> = ({ updateUrlAnchorTexts, urlAnchorTexts, urlAnchorsTextData, isLoading, scenario }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const campaignAnalysis = useSelector(selectCampaignAnalysis);

  const { data: config } = useGetCampaignConfigQuery({ campaignId });

  const [selectedPriorityUrl, setSelectedPriorityUrl] = useState<UrlAnchorTextTableColumns | null>(null);
  const [selectedUrl, setSelectedUrl] = useState<UrlAnchorTextTableColumns | null>(null);

  const userSelectionMap = useMemo(() => getUserSelectionAllocations(config?.data.keywords || []), [config]);
  const keywords = useMemo<AllocationKeyword[]>(
    () => getScenarioUrlKeywords({ campaignAnalysis, scenarioUrls: scenario?.urls || [], userSelectionMap }),
    [userSelectionMap, campaignAnalysis, scenario],
  );

  const tableColumns = useUrlAnchorTextTableColumns();
  const [table, rows] = useTable({
    data: urlAnchorsTextData,
    columns: tableColumns,
    tableCustomOptions: ['allowSelect'],
    tableOptions: {
      enableMultiRowSelection: false,
      meta: {
        setSelectedPriorityUrl,
        selectedUrl,
        setSelectedUrl,
        urlAnchorTexts,
      },
    },
  });

  useEffect(() => {
    for (const row of rows) {
      row.toggleSelected(row.original.url_id === selectedUrl?.url_id);
    }
  }, [rows, selectedUrl?.url_id]);

  return (
    <div className='mt-6 w-full'>
      <div className='w-full rounded-xl bg-white px-5 pb-8 pt-5'>
        <VirtualizedTable
          loadingText={<LoadingTableRow />}
          isLoading={isLoading}
          table={table}
          name='anchortextUrlTable'
          cellStyle='pt-10 xl:pt-8'
          headerClass='text-sm px-1'
          itemHeight={100}
          className='mb-5 h-fit max-h-[1000px]'
          tableClass='shadow-none'
          containerClass='max-h-[calc(100vh-25rem)]'
        />
      </div>
      <AnchorTextDrawer selectedUrl={selectedUrl} onClose={() => setSelectedUrl(null)} urlAnchorTexts={urlAnchorTexts} updateUrlAnchorText={updateUrlAnchorTexts} />
      {Boolean(selectedPriorityUrl) && (
        <ScenarioKeywordsModal
          isOpen={Boolean(selectedPriorityUrl)}
          onClose={() => setSelectedPriorityUrl(null)}
          keywordsAllocations={keywords.filter(({ targetPage }) => targetPage === selectedPriorityUrl?.target_page)}
          setKeywordsAllocations={() => {}}
          userSelectionMap={userSelectionMap}
          maxAnchors={selectedPriorityUrl?.links_per_month || scenario?.links_per_month}
          allowAllocation={false}
          allowPriorityChange={false}
        />
      )}
    </div>
  );
};

export default UrlAnchorTextTable;
