import { Button, Loading, Modal, ModalProps, SelectFilter, SeparatorLine } from '@/components';
import { RadioGroup } from '@headlessui/react';
import { Fragment, useMemo, useState } from 'react';

type MoreAnchorTextActions = {
  createNew: () => void | Promise<void>;
  startOver: (batchId?: number) => void | Promise<void>;
  duplicateCurrentBatch: (batchId?: number, options?: { include_anchors?: boolean }) => void | Promise<void>;
};

interface BaseProps extends ModalProps {
  actions: MoreAnchorTextActions;
  shouldActionCloseModal?: Partial<Record<AnchorTextValue, boolean>>;
  renderChildren?: (batch: Partial<BatchOption> | null) => React.ReactNode;
}

interface WithSelection {
  selectionEnabled: boolean;
  options: Array<OptionType<number>>;
}

interface WithoutSelection {
  selectionEnabled?: never;
  options?: never;
}

type MoreAnchorTextModalProps = BaseProps & (WithSelection | WithoutSelection);

type AnchorTextValue = 'create_new' | 'start_over' | 'duplicate_current_batch';

type AnchorTextSubValue = 'duplicate_everything' | 'duplicate_setup_only';

type AnchorTextOption = {
  label: string;
  text: string;
  value: AnchorTextValue;
  options?: Array<{ label: string; value: AnchorTextSubValue }>;
};

const DEFAULT_OPTION = 'create_new';

const DEFAULT_SUBOPTION = 'duplicate_everything';

const ANCHOR_TEXT_OPTIONS: Array<AnchorTextOption> = [
  {
    label: 'Create New',
    text: 'Create new anchor text batch starting from scratch without replacing the current batch configurations.',
    value: 'create_new',
  },
  {
    label: 'Start Over',
    text: 'Create anchor text batch starting from scratch with replacing the current batch configurations.',
    value: 'start_over',
  },
  {
    label: 'Duplicate Current Batch',
    text: 'Duplicate the current anchor text batch with the same keyword allocation configurations.',
    value: 'duplicate_current_batch',
    options: [
      {
        label: 'Duplicate Everything',
        value: 'duplicate_everything',
      },
      {
        label: 'Duplicate Setup Only',
        value: 'duplicate_setup_only',
      },
    ],
  },
];

const DEFAULT_ACTION_CLOSE_MODAL: Record<AnchorTextValue, boolean> = {
  create_new: true,
  start_over: true,
  duplicate_current_batch: true,
};

type BatchOption = { [key in AnchorTextValue]: undefined | number };

const FIELDS_WITH_SELECTION: Array<AnchorTextValue> = ['start_over', 'duplicate_current_batch'];

const FIELDS_WITH_SUBOPTIONS: Array<AnchorTextValue> = ['duplicate_current_batch'];

const radioButtonStyles =
  'checked:bg-sky-600 checked:hover:bg-sky-600 focus:bg-sky-600 focus:outline-none focus:ring-1 focus:ring-sky-600 checked:focus:bg-sky-600 checked:active:bg-sky-600 disabled:bg-slate-400';

const MoreAnchorTextModal = ({
  isOpen,
  onClose,
  actions,
  isLoading,
  selectionEnabled,
  options,
  renderChildren,
  shouldActionCloseModal = DEFAULT_ACTION_CLOSE_MODAL,
}: MoreAnchorTextModalProps) => {
  const [selectedOption, setSelectedOption] = useState<AnchorTextValue>(DEFAULT_OPTION);
  const [selectedSubOption, setSelectedSubOption] = useState<AnchorTextSubValue>(DEFAULT_SUBOPTION);
  const initialBatches = useMemo(() => {
    if (!options || !options.length || !selectionEnabled) {
      return null;
    }
    return {
      start_over: options[0].value,
      duplicate_current_batch: options[0].value,
    };
  }, [options, selectionEnabled]);

  const [batch, setBatch] = useState<Partial<BatchOption> | null>(initialBatches);

  const handleOptionChange = (value: AnchorTextValue) => {
    setSelectedOption(value);
    if (FIELDS_WITH_SUBOPTIONS.includes(value)) {
      setSelectedSubOption(DEFAULT_SUBOPTION);
    }
  };

  const handleNewBatchOnClick = async () => {
    const { createNew, duplicateCurrentBatch, startOver } = actions;
    if (selectedOption === 'create_new') {
      await createNew();
    }
    if (selectedOption === 'start_over') {
      await startOver(batch?.start_over);
    }
    if (selectedOption === 'duplicate_current_batch' && selectedSubOption) {
      const includeAnchors = selectedSubOption === 'duplicate_everything';
      await duplicateCurrentBatch(batch?.duplicate_current_batch, { include_anchors: includeAnchors });
    }
    const shouldClose = shouldActionCloseModal?.[selectedOption] === undefined ? true : shouldActionCloseModal?.[selectedOption];
    shouldClose && onClose && onClose();
  };

  const renderOptions = (option: AnchorTextOption, index: number) => {
    const enableSelection = FIELDS_WITH_SELECTION.includes(option.value) && selectionEnabled;
    const hasSuboptions = FIELDS_WITH_SUBOPTIONS.includes(option.value);
    const handleSelectBatch = (value: number) => setBatch((prev) => ({ ...prev, [option.value]: value }));

    return (
      <Fragment key={option.value}>
        <div className='flex space-y-1 p-4'>
          <RadioGroup.Option className='p-1' value={option.value}>
            {() => (
              <input type='radio' checked={option.value === selectedOption} onChange={() => handleOptionChange(option.value)} id={option.value} className={radioButtonStyles} />
            )}
          </RadioGroup.Option>
          <div>
            <h3 className='text-base'>{option.label}</h3>
            <p className='text-slate-500'>{option.text}</p>
            <div>
              {option.options && (
                <RadioGroup className='flex gap-2' value={selectedSubOption} onChange={setSelectedSubOption}>
                  {option.options.map(({ value, label }) => (
                    <div key={value} className='flex gap-2 pt-2'>
                      <RadioGroup.Option value={value} disabled={!hasSuboptions || selectedOption !== option.value}>
                        {() => (
                          <input
                            disabled={!hasSuboptions || selectedOption !== option.value}
                            type='radio'
                            className={radioButtonStyles}
                            checked={hasSuboptions && selectedOption === option.value && value === selectedSubOption}
                            onChange={() => setSelectedSubOption(value)}
                            id={value}
                          />
                        )}
                      </RadioGroup.Option>
                      <h3 className='text-sm'>{label}</h3>
                    </div>
                  ))}
                </RadioGroup>
              )}
            </div>
            {enableSelection && options && batch && batch[option.value] && (
              <SelectFilter className='mt-4 w-96 rounded-leap border p-2' value={batch[option.value] || 0} options={options} onChange={handleSelectBatch} />
            )}
          </div>
        </div>
        {index !== ANCHOR_TEXT_OPTIONS.length - 1 && <SeparatorLine className='px-8' />}
      </Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      className='w-[28rem]'
      onClose={onClose}
      title={'More Anchor Text Batches'}
      footer={<MoreAnchorTextModalFooter onClick={handleNewBatchOnClick} isSubmitting={isLoading} />}
    >
      <RadioGroup value={selectedOption} onChange={setSelectedOption}>
        {ANCHOR_TEXT_OPTIONS.map(renderOptions)}
      </RadioGroup>
      {renderChildren && renderChildren(batch)}
    </Modal>
  );
};

export default MoreAnchorTextModal;
export { type MoreAnchorTextActions };

interface MoreAnchorTextModalFooterProps {
  onClick: () => void;
  isSubmitting?: boolean;
}

const MoreAnchorTextModalFooter = ({ onClick, isSubmitting }: MoreAnchorTextModalFooterProps) => {
  return (
    <div className='flex justify-center'>
      <Button className='w-full' disabled={isSubmitting} onClick={onClick}>
        {isSubmitting ? <Loading className=' mx-auto size-5' /> : 'Start New Batch'}
      </Button>
    </div>
  );
};
