import api from '../api';

const deserializePageResponse = <T>(data: Page<T>): DeserializedPage<T> => {
  const nextPage = data.next ? Number(new URL(data.next).searchParams.get('page')) : null;
  const previousPage = data.previous ? Number(new URL(data.previous).searchParams.get('page')) : null;

  return {
    ...data,
    next: nextPage,
    previous: previousPage,
  };
};

const reportsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getReports: build.query<DeserializedPage<LIRReport>, GetReportsArgs>({
      query: ({ search = '', page, page_size = 0 }) => ({
        url: 'reports/',
        params: {
          search,
          page,
          page_size,
        },
      }),
      providesTags: ['LIRReports'],
      transformResponse: deserializePageResponse,
    }),
    createReport: build.mutation<{ data: CreateReportResponse }, CreateReportArgs>({
      query: ({ name, description, companies_ids, file, campaign_id }) => {
        return {
          method: 'POST',
          url: 'reports/',
          body: {
            name,
            description,
            companies_ids,
            file,
            campaign_id,
          },
        };
      },
    }),
    deleteReport: build.mutation<void, number>({
      query: (id) => ({
        method: 'DELETE',
        url: `reports/${id}/`,
      }),
      invalidatesTags: ['LIRReports'],
    }),
  }),
});

const { useGetReportsQuery, useCreateReportMutation, useDeleteReportMutation } = reportsAPI;

export { useGetReportsQuery, useCreateReportMutation, useDeleteReportMutation };
