import { Button, ErrorMessage, Label, SeparatorLine, Tooltip, UploadField, Combination } from '@/components';
import { parseCombinations, trimmer, UrlValidator } from '@/utils/urls';
import { MinusCircleIcon } from '@heroicons/react/20/solid';
import { FolderArrowDownIcon, PlusCircleIcon, MapPinIcon } from '@heroicons/react/24/solid';
import campaignsApi from '@/store/campaign/campaign.api';
import { useField } from 'formik';
import store from '@/store';

import { FC, Fragment, useCallback, useEffect, useMemo, useState, ClipboardEvent } from 'react';

interface CombinationsFieldProps {
  country?: string;
  name: string;
  initialCombinations?: Array<Combination>;
  hasError?: boolean;
  onError?: (hasError: boolean) => void;
  handleCombinationWithSwitch?: (callback: (prev: CombinationWithSwitchMap) => CombinationWithSwitchMap) => void;
  combinationsWithSwitch?: CombinationWithSwitchMap;
  isLocalEnabled?: boolean;
}

const EMPTY_COMBINATION: Combination = {
  url: '',
  keywords: [],
  location_code: null,
};

const CombinationsField: FC<CombinationsFieldProps> = ({
  country,
  name,
  initialCombinations,
  hasError = false,
  onError,
  handleCombinationWithSwitch,
  combinationsWithSwitch,
  isLocalEnabled,
}) => {
  const [isCsvUpload, setIsCsvUpload] = useState(false);
  const [field, meta, { setValue }] = useField<Array<Combination>>(name);
  const showError = !!meta.error && meta.touched;

  const onSwitch = useCallback(
    (index: number, toggled: boolean) => {
      handleCombinationWithSwitch &&
        handleCombinationWithSwitch((prev) => {
          const currentCombination = field.value.at(index);
          if (!currentCombination) {
            return prev;
          }

          const newState = { ...prev };
          newState[index] = {
            ...currentCombination,
            toggled,
          };

          return newState as CombinationWithSwitchMap;
        });
    },
    [field.value, handleCombinationWithSwitch],
  );

  const handleAddUrlOnPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = e.clipboardData;
    const pastedText = clipboardData.getData('text');

    const urls = pastedText.split('\n').filter(trimmer);

    const regex = /,|\t/;

    const combinations = urls.map((url) => {
      const [first, ...rest] = url.split(regex);
      return {
        url: first,
        keywords: rest.map(trimmer),
        location_code: null,
      };
    });

    if (!parseCombinations(combinations).length) {
      return null;
    }
    setValue(combinations);

    combinations.forEach((c, index) => {
      handleCombinationWithSwitch &&
        handleCombinationWithSwitch((prev) => {
          const newState = { ...prev };
          newState[index] = { toggled: c.keywords.length === 0, ...c };

          return newState;
        });
    });
  };

  useEffect(() => {
    onError && onError(false);
  }, [onError, field.value]);

  useEffect(() => {
    if (initialCombinations && initialCombinations.length > 0) {
      setValue(initialCombinations);
    }
  }, [initialCombinations]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLastCombinationSwitchToggled = useMemo(() => {
    const lastCombinationIndex = field.value.length - 1;
    const lastCombination = combinationsWithSwitch && combinationsWithSwitch[lastCombinationIndex];

    return lastCombination && lastCombination.toggled;
  }, [combinationsWithSwitch, field.value]);

  const isLastCombinationValid = useMemo(() => {
    const lastCombination = field.value.at(-1);

    if (!lastCombination) {
      return false;
    }

    const trimmedUrl = lastCombination.url.trim();

    const isValidUrl = UrlValidator.isValidSync(trimmedUrl);

    if (!isValidUrl) {
      return false;
    }

    if (isLastCombinationSwitchToggled) {
      return Boolean(trimmedUrl);
    }

    return Boolean(trimmedUrl && lastCombination.keywords.length > 0);
  }, [field.value, isLastCombinationSwitchToggled]);

  useEffect(() => {
    if (!field.value || field.value.length === 0) {
      setIsCsvUpload(false);
    }
  }, [field.value]);

  const handleAddCombination = () => {
    const combinations = field.value;

    handleCombinationWithSwitch &&
      handleCombinationWithSwitch((prev) => {
        const newState = { ...prev };

        newState[combinations.length] = { toggled: false, ...EMPTY_COMBINATION };

        return newState;
      });

    setValue([...combinations, { url: '', keywords: [], location_code: null }]);
  };

  const handleUpload = useCallback(
    async (results: Array<UploadedCombination>) => {
      const combinations: Combination[] = [];

      for (const row of results) {
        const values = row.map(trimmer).filter(Boolean);

        if (!values.length || !UrlValidator.isValidSync(values.at(0))) {
          continue;
        }

        const url = values.at(0) || '';
        const urlCombination = combinations.find((i) => i.url === url);

        if (values.length === 1) {
          if (urlCombination) {
            continue;
          }

          combinations.push({ url, keywords: [], location_code: null });

          continue;
        }

        if (values.length === 2) {
          const [url, keyword] = values;

          if (urlCombination) {
            urlCombination.keywords.push(keyword);

            continue;
          }

          combinations.push({ url, keywords: [keyword], location_code: null });

          continue;
        }

        if (values.length === 4) {
          const [url, keyword, city, state] = values;

          if (urlCombination) {
            urlCombination.keywords.push(keyword);

            continue;
          }

          const combination = { url, keywords: [keyword], location_code: null } as Combination;

          const params: { name?: string; country?: string } = { name: `${city},${state}` };

          if (country) {
            params['country'] = country;
          }

          const { data } = await store.dispatch(campaignsApi.endpoints.getLocations.initiate(params, { forceRefetch: true }));

          if (data && data.results && data.results.length === 1) {
            combination.location_code = data.results[0].code;
          }

          combinations.push(combination);

          continue;
        }
      }

      setValue(combinations);

      combinations.forEach((c, index) => {
        if (!c.keywords.length) {
          handleCombinationWithSwitch &&
            handleCombinationWithSwitch((prev) => {
              const newState = { ...prev };
              newState[index] = { toggled: c.keywords.length === 0, ...c };

              return newState;
            });
        }
      });
      setIsCsvUpload(true);
    },
    [country],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteFile = useCallback(() => {
    setValue([]);
    setIsCsvUpload(false);
  }, [setValue]);

  const renderCombinations = (_: Combination, index: number) => {
    const deleteCombination = () => {
      const valuesCopy = [...field.value];
      valuesCopy.splice(index, 1);
      handleCombinationWithSwitch &&
        handleCombinationWithSwitch((prev) => {
          const newState = { ...prev };

          delete newState[index];

          return newState;
        });

      setValue(valuesCopy.length !== 0 ? valuesCopy : [{ url: '', keywords: [], location_code: null }]);
    };

    return (
      <Combination
        onPaste={handleAddUrlOnPaste}
        initialSwitchState={combinationsWithSwitch && combinationsWithSwitch[index]?.toggled}
        key={index}
        field={`${name}[${index}]`}
        onDelete={deleteCombination}
        hasError={hasError}
        onSwitch={onSwitch}
        isLocalEnabled={isLocalEnabled}
        index={index}
        onError={onError}
        country={country}
      />
    );
  };

  const renderUploadedCombinations = (comb: Combination, index: number) => {
    const { url = '', keywords, location_code } = comb;

    const kwCount = keywords.length;

    const handleDeleteRow = () => {
      const filteredCombinations = field.value.filter((comb) => comb.url !== url);
      setValue(filteredCombinations);
    };

    if (comb.keywords.length === 0) {
      return renderCombinations(comb, index);
    }

    return (
      <div key={url} className='flex w-full items-center justify-center gap-2 px-5'>
        <div className='my-1 w-4/6 rounded-[3px] border border-gray-200 p-2 shadow-md'>
          <Tooltip id={`url-${index}`} content={<p className='truncate'>{url}</p>}>
            <p className='truncate'>{url}</p>
          </Tooltip>
        </div>
        <div className='my-1 w-1/6 rounded-[3px] border border-gray-200 py-2 shadow-md'>
          <p className='overflow-hidden text-ellipsis whitespace-nowrap  text-center'>{kwCount}</p>
        </div>
        <div className='my-1 flex'>
          {location_code && <MapPinIcon className='h-6 w-6' />}
          <MinusCircleIcon className='h-6 w-6 cursor-pointer text-red-600' onClick={handleDeleteRow} />
        </div>
      </div>
    );
  };

  const handleDownloadSample = () => {
    const link = document.createElement('a');
    link.href = '/samples/sample.csv';
    link.target = '_blank';
    link.download = 'sample.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Fragment>
      {!isCsvUpload && <div className='flex flex-col gap-3'>{field.value.map(renderCombinations)}</div>}

      {isCsvUpload && field.value.length > 0 && (
        <div className='mb-2 '>
          <div className='flex items-center justify-around'>
            {field.value.every((comb) => comb.keywords.length !== 0) && (
              <>
                <Label className='w-1/2'>URLs</Label>
                <Label className='w-1/6'># of Keywords</Label>
              </>
            )}
          </div>
          <div className='flex flex-col gap-2'>{field.value.map(renderUploadedCombinations)}</div>
        </div>
      )}

      <SeparatorLine />
      <div className='flex items-center justify-center gap-2 p-5 '>
        <UploadField onUpload={handleUpload} title='Import URLs and Keywords' onDelete={handleDeleteFile} showProgress />
        <Button variant='outline-light' className='flex items-center gap-2 whitespace-nowrap ' onClick={handleDownloadSample}>
          <FolderArrowDownIcon className='h-6 w-6' />
          Download Sample
        </Button>
        <Button
          type='button'
          variant='outline-light'
          onClick={handleAddCombination}
          className='flex items-center gap-2 whitespace-nowrap'
          disabled={isCsvUpload || !isLastCombinationValid}
        >
          <PlusCircleIcon className='w-6' />
          <p>Add New Combination</p>
        </Button>
      </div>
      {showError && !field.value.length && <ErrorMessage>{meta.error}</ErrorMessage>}
    </Fragment>
  );
};

export default CombinationsField;
