import { Avatar, Modal, ModalProps, SearchBarDebounce, SelectFilter } from '@/components';
import { selectAuthUser } from '@/store/auth/auth.selector';
import { useUsersListQuery } from '@/store/users/users.api';
import cn from '@/utils/style';
import { EyeIcon, PencilSquareIcon, UserCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

interface CollaboratorModalProps extends Omit<ModalProps, 'onConfirm'> {
  onConfirm: (collaborators: Array<CampaignUser>) => void;
  campaignId: number;
}

const COLLABORATOR_ROLE_OPTIONS: Array<OptionType<UserRole | 'no_access'>> = [
  {
    label: 'Can Edit',
    value: 'manager',
  },
  {
    label: 'Can View',
    value: 'viewer',
  },
  {
    label: 'No Access',
    value: 'no_access',
  },
];

const COLLABORATOR_FILTER_ROLES = [
  {
    label: 'All',
    value: 'all',
    icon: UserCircleIcon,
  },
  {
    label: 'Editor',
    value: 'manager',
    icon: PencilSquareIcon,
  },
  {
    label: 'Viewer',
    value: 'viewer',
    icon: EyeIcon,
  },
  {
    label: 'No Access',
    value: 'no_access',
    icon: XCircleIcon,
  },
];

const getUserRoleMap = (users: User[], campaignId: number) => {
  return users.reduce(
    (roles, user) => ({
      ...roles,
      [user.id]: user.campaigns.find((camp) => camp.id === campaignId)?.role || 'no_access',
    }),
    {},
  );
};

const getDisplayedUsers = (users: User[], user: User | null, campaignId: number) => {
  return users
    .filter(({ id }) => id !== user?.id)
    .sort((a, b) => {
      const aRole = a.campaigns.find(({ id }) => id === campaignId)?.role;
      const bRole = b.campaigns.find(({ id }) => id === campaignId)?.role;

      if (['owner', 'creator'].includes(aRole || '')) {
        return -1;
      }

      if (['owner', 'creator'].includes(bRole || '')) {
        return 1;
      }

      return 0;
    });
};

const INITIAL_PAGE = 1;
// Doing page_size 0 means that we want to get all the users
const INITIAL_PAGE_SIZE = 0;

const CollaboratorModal: FC<CollaboratorModalProps> = ({ isOpen, onClose, onConfirm, campaignId }) => {
  const user = useSelector(selectAuthUser);
  const [usersRole, setUsersRole] = useState<Record<string, UserRole | 'no_access'>>({});
  const [filterBy, setFilterBy] = useState<UserRole | 'no_access' | 'all' | undefined>(undefined);
  const [search, setSearch] = useState('');

  const {
    data: users,
    isLoading: usersLoading,
    isFetching,
  } = useUsersListQuery({
    page: INITIAL_PAGE,
    page_size: INITIAL_PAGE_SIZE,
    filter: {
      campaignId,
    },
  });

  const displayedUsers = useMemo(() => getDisplayedUsers(users?.results || [], user, campaignId), [campaignId, user, users]);

  const filteredUsers = useMemo(
    () =>
      displayedUsers.filter((u) => {
        const searchByName = u.firstName.toLowerCase().includes(search.toLowerCase());
        const searchByLastName = u.lastName.toLowerCase().includes(search.toLowerCase());
        const searchByEmail = u.email.toLowerCase().includes(search.toLowerCase());

        const searchResult = searchByName || searchByLastName || searchByEmail;
        const roleMatchesResult = usersRole[u.id] === filterBy;

        if (filterBy === 'all' || !filterBy) {
          return searchResult;
        }

        return searchResult && roleMatchesResult;
      }),
    [displayedUsers, filterBy, usersRole, search],
  );

  const authUserRole = useMemo(() => {
    const role = user && (user.campaigns || []).find((camp) => camp.id === campaignId)?.role;

    return user?.isSuperuser ? 'Super User' : role || 'viewer';
  }, [campaignId, user]);

  useEffect(() => {
    if (filterBy) {
      return;
    }
    setUsersRole(getUserRoleMap(displayedUsers, campaignId));
  }, [campaignId, displayedUsers, filterBy]);

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const handleCollaboratorConfirm = () => {
    const collaborators = Object.keys(usersRole)
      .filter((key) => usersRole[key] !== 'no_access')
      .map((userId) => ({
        id: Number(userId),
        role: usersRole[userId] as UserRole,
      }));

    onConfirm(collaborators);
  };

  const handleCollaboratorFilterChange = useCallback((value: string) => {
    setFilterBy(value as UserRole | 'no_access');
  }, []);

  const renderCollaborators = (collaborator: User) => {
    const collaboratorRole = collaborator.campaigns.find((campaign) => campaign.id === campaignId)?.role;
    const isOwnerOrCreator = collaboratorRole && (collaboratorRole === 'owner' || collaboratorRole === 'creator');

    const handleUserRoleChange = (value: string) => {
      setUsersRole((prev) => ({
        ...prev,
        [collaborator.id]: value as UserRole,
      }));
    };

    return (
      <div key={collaborator.id} className='flex w-full items-center justify-between py-1'>
        <Avatar user={collaborator} />
        {isOwnerOrCreator ? (
          <p>{collaboratorRole}</p>
        ) : (
          <SelectFilter
            options={COLLABORATOR_ROLE_OPTIONS}
            value={usersRole[collaborator.id]}
            onChange={handleUserRoleChange}
            optionsClassName='w-32 ml-11'
            className='w-44 pl-16'
          />
        )}
      </div>
    );
  };

  if (!user) {
    return null;
  }

  const renderUsers = () => {
    if (usersLoading || isFetching) {
      return <p className='text-gray-500'>Loading users...</p>;
    }

    if (filteredUsers.length === 0) {
      return <p className='text-gray-500'>No users found</p>;
    }

    return filteredUsers.map((collaborator) => renderCollaborators(collaborator));
  };

  return (
    <Modal isOpen={isOpen} title='Collaborator Access' onClose={onClose} onConfirm={handleCollaboratorConfirm} className='min-w-modal'>
      <div className='flex flex-col gap-y-4'>
        <SearchBarDebounce initialValue='' onChange={handleSearch} delay={500} inputClassName='w-full' />
        <div className='flex items-center gap-4'>
          <p className='font-bold text-gray-500'>Filter By:</p>
          {COLLABORATOR_FILTER_ROLES.map(({ label, value, icon: Icon }) => {
            const isAll = value === 'all' && !filterBy;
            return (
              <button
                key={value}
                className={cn(
                  'flex cursor-pointer items-center gap-1 text-sky-600 duration-300 disabled:opacity-50',
                  (isAll || filterBy === value) && 'underline-sky-600 underline underline-offset-4',
                )}
                onClick={() => handleCollaboratorFilterChange(value)}
              >
                <Icon className='h-4' />
                <p>{label}</p>
              </button>
            );
          })}
        </div>

        <div className='flex items-center justify-between'>
          <Avatar user={user} />
          <p className='font-bold capitalize'>{authUserRole}</p>
        </div>

        <div className='h-96 overflow-y-auto'>{renderUsers()}</div>
      </div>
    </Modal>
  );
};

export default CollaboratorModal;
