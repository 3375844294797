import { LOOMFLOWS_ACCESS_TOKEN, LOOMFLOWS_SRC } from '@/constants/application';
import { useCallback, useEffect, useState } from 'react';
import useScript from 'react-script-hook';

const FEEDBACK_MODAL_SELECTOR = 'loomflows-widget';

const SRC = LOOMFLOWS_SRC || '';

const useFeedbackModal = () => {
  const [loading, error] = useScript({ src: SRC });
  const [callback, setCallback] = useState<(() => void) | null>(null);

  const getBtnCallback = useCallback((element: Element) => {
    const btn = element.querySelector('button');
    if (btn) {
      setCallback(() => btn.click.bind(btn));
      btn.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    window.LoomFlows = {};
    window.LoomFlows.access_token = LOOMFLOWS_ACCESS_TOKEN;

    const loomElement = document.querySelector(FEEDBACK_MODAL_SELECTOR);
    if (!loomElement) return;

    getBtnCallback(loomElement);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          getBtnCallback(loomElement);
        }
      });
    });
    observer.observe(loomElement, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [loading, error, getBtnCallback]);

  return [callback, loading, error] as const;
};

export { useFeedbackModal };
