import { STEPS } from '@/constants/defaultValues';
import { useGetCampaignQuery, useGetCampaignConfigQuery, useGetAnchorTextBatchesQuery } from '@/store/campaign/campaign.api';
import { selectAllScenariosAreValid } from '@/store/campaign/campaign.selector';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

type Steps = typeof STEPS[number] | '/';

const analysisStepsInitial: Array<AnalysisStepObject> = [
  {
    slug: 'analysis',
    label: 'Link Gap Analysis',
    url: '/analysis',
    isDisabled: false,
    isDone: false,
    isActive: false,
    canNavigate: true,
    number: 1,
  },
  {
    slug: 'scenario-review',
    label: 'Proposal Review',
    url: '/scenario-review',
    isDisabled: false,
    isDone: false,
    isActive: false,
    canNavigate: true,
    number: 2,
  },
  {
    slug: 'anchor-text-generator',
    label: 'Anchor Text Generator',
    url: '/anchor-text-generator',
    isDisabled: false,
    isDone: false,
    isActive: false,
    canNavigate: true,
    number: 3,
  },
  {
    slug: 'summary',
    label: 'Final Summary',
    url: '/summary',
    isDisabled: false,
    isDone: false,
    isActive: false,
    canNavigate: true,
    number: 4,
  },
];

const useSteps = () => {
  const { campaignId } = useParams() as { campaignId: string };
  const location = useLocation();

  const { data: batches } = useGetAnchorTextBatchesQuery({ campaignId, pageSize: 500 });
  const activeBatch = useMemo(() => batches?.results.find((batch) => batch.is_active), [batches?.results]);

  const allScenariosAreValid = useSelector(selectAllScenariosAreValid);
  const { data: campaign = { id: null, status: null } } = useGetCampaignQuery({ campaignId });
  const { data: campaignConfig } = useGetCampaignConfigQuery({ campaignId });

  const [previousStep, setPreviousStep] = useState<Steps>('/');
  const [nextStep, setNextStep] = useState<Steps>('/');
  const [analysisSteps, setAnalysisSteps] = useState(analysisStepsInitial);

  const currentStep = useMemo(() => location.pathname.split('/').find((s) => STEPS.includes(s as typeof STEPS[number])) as Steps, [location.pathname]);
  const hasAnchorTexts = useMemo(() => Boolean(campaignConfig?.data.has_anchor_texts), [campaignConfig?.data]);

  const isAt = (step: Steps) => currentStep === step;

  const userLastStepNumber = useMemo(() => {
    const userLastStep = analysisSteps.find((i) => i.slug === campaignConfig?.data.user_progress?.last_step);

    if (!userLastStep) {
      return 0;
    }

    return userLastStep.number;
  }, [analysisSteps, campaignConfig]);

  useEffect(() => {
    const currentStepIndex = STEPS.findIndex((step) => step === currentStep);

    let prev: Steps = currentStepIndex === 0 ? '/' : STEPS.at(currentStepIndex - 1) || '/';
    let next: Steps = STEPS.at(currentStepIndex + 1) || '/';

    if (prev === 'anchor-text-results' && !hasAnchorTexts) {
      prev = 'anchor-text-generator';
    }

    if (prev === 'anchor-text-generator' && hasAnchorTexts) {
      prev = 'scenario-review';
    }

    if (next === 'anchor-text-generator' && hasAnchorTexts && activeBatch) {
      next = ('anchor-text-results/' + activeBatch.id) as Steps;
    }

    if (currentStep === 'summary' && !hasAnchorTexts) {
      prev = 'anchor-text-generator';
    }

    if (currentStep === 'summary') {
      prev = 'anchor-text-batches' as Steps;
    }

    setPreviousStep(prev);
    setNextStep(next);

    setAnalysisSteps((steps) =>
      steps.map((i) => {
        const isDisabled = !allScenariosAreValid && i.number > 1;
        const isDone = i.number < userLastStepNumber || campaign.status === 'kicked_off';
        const canNavigate = i.number <= userLastStepNumber || campaign.status === 'kicked_off';

        const initialData = analysisStepsInitial.find(({ slug }) => slug === i.slug) as AnalysisStepObject;
        const initialUrl = i.slug === 'anchor-text-generator' && batches?.results.length ? '/anchor-text-batches' : initialData.url;

        const url = `/campaigns/${campaign.id}${initialUrl}`;
        const isActive = currentStep === i.slug;

        return { ...i, isDisabled, isDone, canNavigate, url, isActive };
      }),
    );
  }, [currentStep, hasAnchorTexts, allScenariosAreValid, campaign.status, campaign.id, userLastStepNumber, batches, activeBatch]);

  return { previousStep, currentStep, nextStep, analysisSteps, isAt };
};

export { useSteps };
