import { Skeleton } from '@/components';
import { useGetAnchorTextBatchesQuery } from '@/store/campaign/campaign.api';
import { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { AnchorTextOVerviewTable, AnchorTextOverviewGraph } from './components';

const AnchorTextOverview: FC = () => {
  const { campaignId } = useParams() as { campaignId: string };
  const { data: anchorTextBatches, isLoading: areBatchesLoading } = useGetAnchorTextBatchesQuery({ campaignId, pageSize: 500 });

  if (areBatchesLoading) {
    return (
      <div className='flex flex-col gap-4'>
        <Skeleton className='h-96 w-full rounded-leap py-20'></Skeleton>
        <Skeleton className='h-60 w-full rounded-leap py-20'></Skeleton>
      </div>
    );
  }

  if (!anchorTextBatches?.results.length) {
    return (
      <div className='flex h-full w-full justify-center rounded-leap bg-white py-20 text-left text-gray-800 shadow-md'>
        <p className='my-auto text-2xl'>Anchor Text not generated.</p>
      </div>
    );
  }

  return (
    <Fragment>
      <AnchorTextOVerviewTable isLoading={areBatchesLoading} />
      <AnchorTextOverviewGraph results={anchorTextBatches.anchor_text_totals} />
    </Fragment>
  );
};

export default AnchorTextOverview;
