import { Button, Modal, ModalProps, Pill, TextareaInputField, ToastCard, UploadButton } from '@/components';
import Autocomplete from '@/components/Autocomplete';
import { BASE_API_URL } from '@/constants/application';
import { INITIAL_PAGE, INITIAL_PAGE_SIZE } from '@/constants/pagination';
import useDebounce from '@/hooks/debounce';
import api from '@/store/api';
import { selectAuthToken } from '@/store/auth/auth.selector';
import { useGetCampaignListQuery } from '@/store/campaign/campaign.api';
import { useGetCompanyListQuery } from '@/store/company/company.api';
import { useCreateReportMutation } from '@/store/reports/reports.api';
import { DocumentTextIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Form, FormikProvider, useFormik } from 'formik';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

interface LIRImportModalProps extends ModalProps {}

interface ImportFormValues {
  description?: string;
  file: File | null;
}

const formatCompanies = (companies: Company[] | undefined) => {
  if (!companies) {
    return [];
  }

  return companies.map((company) => ({
    label: company.name,
    value: Number(company.id),
  }));
};

const formatCampaigns = (campaigns: CampaignJSON[] | undefined) => {
  if (!campaigns) {
    return [];
  }

  return campaigns.map((campaign) => ({
    label: campaign.name,
    value: campaign.id,
  }));
};

const useInfiniteCampaignQuery = (filters: CampaignFilters) => {
  const [page, setPage] = useState(INITIAL_PAGE);
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const query = useGetCampaignListQuery({ page, page_size: INITIAL_PAGE_SIZE, ...filters });

  const { isFetching, isLoading, isError } = query;

  useEffect(() => {
    if (!isFetching && !isLoading && !isError && inView && query.data && query.data.next) {
      setPage(query.data.next);
    }
  }, [isFetching, inView, isLoading, isError, query]);

  useEffect(() => {
    setPage(INITIAL_PAGE);
  }, [filters]);

  return [ref, query] as const;
};

const LIRImportModal = ({ isOpen, onClose }: LIRImportModalProps) => {
  const authToken = useSelector(selectAuthToken);
  const { data: companies, isLoading: isCompaniesLoading } = useGetCompanyListQuery();

  const [selectedCompanies, setSelectedCompanies] = useState<OptionType<number>[]>([]);
  const [search, setSearch] = useState('');
  const uploadButtonRef = useRef<HTMLInputElement>(null);
  const [createReport] = useCreateReportMutation();
  const dispatch = useDispatch();
  const [searchCampaign, setSearchCampaign] = useState('');
  const debouncedCampaignSearch = useDebounce(searchCampaign, 500);
  const queryArgs = useMemo(
    () => ({
      name__icontains: debouncedCampaignSearch,
      isArchived: false,
    }),
    [debouncedCampaignSearch],
  );

  const [ref, { data: campaigns, isLoading, isFetching }] = useInfiniteCampaignQuery(queryArgs);

  const campaignsOptions = useMemo(() => formatCampaigns(campaigns?.results), [campaigns]);

  const companiesOptions: Array<OptionType<number>> = useMemo(() => {
    if (!search) {
      return formatCompanies(companies);
    }

    return formatCompanies(companies?.filter((company) => company.name.toLowerCase().includes(search.toLowerCase())));
  }, [companies, search]);

  const [currentCompany, setCurrentCompany] = useState<number | null>(null);
  const [currentCampaign, setCurrentCampaign] = useState<number | null>(null);

  const handleCompanySearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleCampaignSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchCampaign(e.target.value);
  };

  const handleCompanySelect = (company: number) => {
    setCurrentCompany(company);
    const selectedCompany = companiesOptions.find((c) => c.value === company);
    if (!selectedCompany) {
      return;
    }

    setSelectedCompanies((prev) => Array.from(new Set([...prev, selectedCompany])));
  };

  const handleCampaignSelect = (campaign: number | null) => {
    setCurrentCampaign(campaign);
  };

  const handleUploadButtonClick = () => {
    if (uploadButtonRef.current) {
      uploadButtonRef.current.click();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    formik.setFieldValue('file', file);
  };

  const ids = useMemo(() => selectedCompanies.map((company) => company.value), [selectedCompanies]);

  const formik = useFormik<ImportFormValues>({
    initialValues: {
      description: '',
      file: null,
    },
    onSubmit: async ({ file, description }, actions) => {
      if (ids.length <= 0 || !file) {
        return;
      }
      actions.setSubmitting(true);
      const {
        data: { id },
      } = await createReport({ name: file.name, description, companies_ids: ids, file, campaign_id: currentCampaign }).unwrap();

      try {
        const formData = new FormData();
        formData.append('report_url', file);

        await fetch(`${BASE_API_URL}reports/${id}/upload/`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        dispatch(api.util.invalidateTags(['LIRReports']));
        toast(<ToastCard message='File Uploaded' />, { type: 'success' });
      } catch (e) {
        toast(<ToastCard message='Error uploading file' />, { type: 'error' });
      } finally {
        actions.setSubmitting(false);
        onClose && onClose();
      }
    },
  });

  const handleDeleteFile = () =>
    formik.setValues((prev) => {
      return {
        ...prev,
        file: null,
      };
    });

  return (
    <Modal isOpen={isOpen} title='Add Link Impact Report' onClose={onClose} className='w-96 max-w-96'>
      <FormikProvider value={formik}>
        <Form>
          <div className='flex w-full flex-col gap-y-5'>
            <div className='w-full'>
              {!formik.values.file ? (
                <UploadButton
                  variant='dashed-primary'
                  iconClassName='text-sky-600'
                  className='w-full justify-center'
                  accept='.pdf'
                  title='Upload PDF File'
                  uploadButtonRef={uploadButtonRef}
                  onClick={handleUploadButtonClick}
                  onChange={handleFileChange}
                />
              ) : (
                <div className='flex w-full items-center justify-between  rounded-leap border p-2'>
                  <div className='flex max-w-[85%] items-center gap-2'>
                    <DocumentTextIcon className='size-6 text-sky-600' />
                    <span className=' truncate'>{formik.values.file.name}</span>
                  </div>
                  <button onClick={handleDeleteFile}>
                    <TrashIcon className='size-5' />
                  </button>
                </div>
              )}
            </div>
            <div className='space-y-2'>
              <Autocomplete
                label='Assign Company'
                loading={isCompaniesLoading}
                onSelect={handleCompanySelect}
                value={currentCompany}
                options={companiesOptions}
                onSearch={handleCompanySearch}
              />
              {selectedCompanies.length > 0 && <p>Selected Companies:</p>}
              <div className='flex max-h-16 w-full flex-wrap gap-2 overflow-auto'>
                {selectedCompanies.map((company) => (
                  <Pill key={company.value} onClick={() => setSelectedCompanies((prev) => prev.filter((c) => c.value !== company.value))}>
                    {company.label}
                  </Pill>
                ))}
              </div>
            </div>
            <Autocomplete
              paginationRef={ref}
              label='Assign Campaign'
              onSelect={handleCampaignSelect}
              value={currentCampaign}
              options={campaignsOptions}
              onSearch={handleCampaignSearch}
              loading={isFetching || isLoading}
              withDeleteIcon
              nullable
            />
            <TextareaInputField name='description' label='Description' />
            <Button disabled={!ids.length || !formik.values.file} isLoading={formik.isSubmitting} variant='primary' type='submit'>
              Add Link Impact Report
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default LIRImportModal;
