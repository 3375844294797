import { Breadcrumbs, Button, WarningModal } from '@/components';
import { useSteps } from '@/hooks/steps';
import { useToggle } from '@/hooks/toggle';
import { Page } from '@/layouts';
import {
  useDeleteAnchorTextBatchMutation,
  useDuplicateAnchorTextBatchMutation,
  useGetAnchorTextBatchesQuery,
  useGetCampaignQuery,
  useRegenerateUrlAnchorTextsMutation,
  useSaveCampaignConfigMutation,
  useStartOverAnchorTextBatchMutation,
} from '@/store/campaign/campaign.api';
import { selectScenarios, selectSteps } from '@/store/campaign/campaign.selector';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import cn from '@/utils/style';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { AnalysisFooter, AnalysisTopbar } from '../../components';
import FinalizeSectionPageSkeleton from '../FinalizeSectionPage/FinalizeSectionPageSkeleton';
import { AnchorTextResultsTabs, ReadyToReviewModal } from './components';
import AnchorTextGeneratorPageSkeleton from '../AnchorTextGeneratorPage/AnchorTextGeneratorPageSkeleton';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { MoreAnchorTextModal } from './components/MoreAnchorTextModal';
import api from '@/store/api';

const AnchorTextResultsPage = () => {
  const { campaignId, batchId } = useParams() as { campaignId: string; batchId: string };
  const navigate = useNavigate();
  const navigation = useNavigation();
  const dispatch = useDispatch<AppDispatch>();
  const steps = useSelector(selectSteps);
  const scenarios = useSelector(selectScenarios);
  const { previousStep, nextStep } = useSteps();

  const [pollingInterval, setPollingInterval] = useState(0);

  const { data: campaign, isLoading: loadingCampaign } = useGetCampaignQuery({ campaignId }, { pollingInterval });
  const [deleteBatchMutation, { isLoading: deletingAnchors }] = useDeleteAnchorTextBatchMutation();
  const [updateConfig, { isLoading: isUpdatingConfig }] = useSaveCampaignConfigMutation();
  const [duplicateCurrentBatchMutation, { isLoading: isDuplicatingBatch }] = useDuplicateAnchorTextBatchMutation();
  const [startOver, { isLoading: isStartingOver }] = useStartOverAnchorTextBatchMutation();
  const [regenerateUrlAnchorTexts, { isLoading: isRegeneratingAnchors }] = useRegenerateUrlAnchorTextsMutation();

  const { data: batches } = useGetAnchorTextBatchesQuery({ campaignId, pageSize: 500 });
  const currentBatch = useMemo(() => batches?.results?.find((batch) => batch.id === Number(batchId)), [batches, batchId]);

  const { value: isMoreAnchorTextModalOpen, toggle: toggleMoreAnchorTextModal } = useToggle();
  const { value: isRegenerateModalOpen, toggle: toggleRegenerateModal } = useToggle();
  const { value: isReadyToReviewModalOpen, toggle: toggleReadyToReviewModal } = useToggle();
  const { value: isStartOverConfirmModalOpen, toggle: toggleStartOverConfirmModal } = useToggle({ initialValue: false });
  const approvedScenario = useMemo(() => scenarios.find(({ is_approved }) => is_approved), [scenarios]);
  const inProgress = useMemo(() => ['in_progress', 'queued'].includes(campaign?.anchors_generation_status || ''), [campaign]);

  const isFetchInProgress = useMemo(() => loadingCampaign || inProgress, [loadingCampaign, inProgress]);

  const handleSave = useCallback(() => {
    dispatch(setIsDirty({ step: 'anchor-text-generator', isDirty: false }));
  }, [dispatch]);

  const handleDuplicateBatch = useCallback(
    async (_?: number, options?: { include_anchors?: boolean }) => {
      const res = await duplicateCurrentBatchMutation({ batchId: Number(batchId), campaignId, include_anchors: options?.include_anchors }).unwrap();

      const urls = batches?.results
        .find((batch) => batch.id === Number(batchId))
        ?.urls.map((url) => ({
          id: url.url_id,
          counts: {
            generic: url.generic_match,
            partial: url.partial_match,
            exact: url.exact_match,
            anchors_per_keyword_distribution: [],
          },
        }));

      if (!options?.include_anchors && urls) {
        await regenerateUrlAnchorTexts({ campaign_id: campaignId, urls, batch_id: res.id }).unwrap();
        dispatch(api.util.invalidateTags(['campaign']));
      }
      navigate('../anchor-text-batches');
    },
    [batchId, campaignId, duplicateCurrentBatchMutation, navigate, regenerateUrlAnchorTexts, batches, dispatch],
  );

  const handleStartOver = useCallback(async () => {
    toggleStartOverConfirmModal(false);
    await startOver({ batchId: Number(batchId), campaignId });
    navigate(`../anchor-text-generator?batch_id=${batchId}`);
  }, [batchId, campaignId, startOver, navigate, toggleStartOverConfirmModal]);

  const moreAnchorTextActions = useMemo(
    () => ({
      createNew: () => navigate('../anchor-text-generator'),
      duplicateCurrentBatch: handleDuplicateBatch,
      startOver: () => toggleStartOverConfirmModal(true),
    }),
    [handleDuplicateBatch, navigate, toggleStartOverConfirmModal],
  );

  useEffect(() => {
    if (inProgress) {
      return setPollingInterval(3000);
    }

    setPollingInterval(0);
  }, [inProgress]);

  if (!approvedScenario) {
    return null;
  }

  const handleDeleteBatch = async () => {
    await deleteBatchMutation({ batchId: Number(batchId), campaignId });
    const batchesLength = batches?.results.length;

    if (batchesLength === 1) {
      return navigate('../anchor-text-generator');
    }

    return navigate('../anchor-text-batches');
  };

  const handlePrevious = () => navigate(`../${previousStep}`);
  const handleNext = async () => {
    if (!isReadyToReviewModalOpen) {
      return toggleReadyToReviewModal(true);
    }
    // await updateBatch({ campaignId, batchId: Number(batchId), is_active: true });
    await updateConfig({ campaignId, config: { user_progress: { last_step: 'summary' } } });
    navigate(`../${nextStep}`);
  };

  if (loadingCampaign) {
    return (
      <div className='absolute inset-0 z-10 bg-slate-50'>
        <FinalizeSectionPageSkeleton />;
      </div>
    );
  }

  if (navigation.state === 'loading') {
    return <AnchorTextGeneratorPageSkeleton />;
  }

  if (!campaign || !approvedScenario) {
    return null;
  }

  return (
    <Fragment>
      <Page>
        <AnalysisTopbar onSave={handleSave} isDirty={steps['anchor-text-generator'].isDirty} />
        <Page.Body>
          <div className='flex justify-between'>
            <div className='flex flex-col items-start'>
              <Breadcrumbs items={[{ label: 'Batch List', url: '../anchor-text-batches' }, { label: currentBatch?.title || `Anchor Text Batch ${batchId}` }]} />
              <h2 className='text-left font-semibold'>{currentBatch?.title || `Anchor Text Batch ${batchId}`}</h2>
            </div>
            <div className='flex gap-2'>
              <Button disabled={isFetchInProgress} className='flex items-center gap-2 text-sm text-slate-800' variant='outline-light' onClick={() => toggleRegenerateModal(true)}>
                <TrashIcon className={cn('size-5 text-slate-400')} />
                Delete Current Batch
              </Button>
              <Button disabled={isFetchInProgress} className='flex items-center gap-2 text-sm' onClick={() => toggleMoreAnchorTextModal(true)}>
                <PlusIcon className={cn('size-5')} />
                More Anchor Text Batches
              </Button>
            </div>
          </div>
          <AnchorTextResultsTabs isLoading={isFetchInProgress} />
        </Page.Body>
        <Page.Footer>
          <AnalysisFooter nextDisabled={isFetchInProgress} onPrevious={handlePrevious} onSave={handleSave} onNext={handleNext} />
        </Page.Footer>
      </Page>
      {isMoreAnchorTextModalOpen && (
        <MoreAnchorTextModal
          actions={moreAnchorTextActions}
          isOpen={isMoreAnchorTextModalOpen}
          onClose={() => toggleMoreAnchorTextModal(false)}
          isLoading={deletingAnchors || isUpdatingConfig || isDuplicatingBatch || isStartingOver || isRegeneratingAnchors}
          shouldActionCloseModal={{
            start_over: false,
          }}
          renderChildren={() => (
            <WarningModal
              buttonsLoading={isStartingOver}
              className='w-modal'
              isOpen={isStartOverConfirmModalOpen}
              onClose={() => toggleStartOverConfirmModal(false)}
              onConfirm={handleStartOver}
              message='Are you sure you want to start over the Anchor Text Generator process for batch? All progress of the selected Anchor Text batch will be lost'
            />
          )}
        />
      )}
      {isRegenerateModalOpen && (
        <WarningModal
          isOpen={isRegenerateModalOpen}
          message='Are you sure you want to delete this batch? This action is irreversible.'
          onConfirm={handleDeleteBatch}
          onClose={() => toggleRegenerateModal(false)}
          title='Delete Current Batch'
          buttonsLoading={deletingAnchors}
        />
      )}

      {isReadyToReviewModalOpen && <ReadyToReviewModal isOpen={true} onClose={() => toggleReadyToReviewModal(false)} onReview={handleNext} />}
    </Fragment>
  );
};

export default AnchorTextResultsPage;
