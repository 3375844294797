type AnchorTextBatchFooterProps = {
  data: AnchorTextTotal | null;
  withCheckbox?: boolean;
};

const AnchorTextBatchTableFooter = ({ data, withCheckbox = false }: AnchorTextBatchFooterProps) => {
  if (!data) return null;
  const { exact, generic, partial, total } = data;

  return (
    <tr className='h-8  bg-blue-100 py-2 pl-10 text-left text-sm'>
      {withCheckbox && <td></td>}
      <td className='pl-4'>Grand Total:</td>
      <td className='pl-4'>{exact} Links</td>
      <td className='pl-4'>{partial} Links</td>
      <td className='pl-4'>{generic} Links</td>
      <td className='pl-4'>{total}</td>
      <td className='pl-4'></td>
      <td></td>
    </tr>
  );
};

export default AnchorTextBatchTableFooter;
