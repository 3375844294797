import {
  FiltersModal,
  FiltersPills,
  Modal,
  SearchBarDebounce,
  SelectFilter,
  VirtualizedTable,
  TableVisibilityMenu,
  Button,
  SuccessModal,
  Checkbox,
  Label,
  Skeleton,
} from '@/components';
import { NUMERIC_FILTERS_OPTIONS } from '@/constants/defaultValues';
import { useTable } from '@/hooks/table';
import { useToggle } from '@/hooks/toggle';
import { useGetCampaignConfigQuery, useGetCampaignQuery, useGetCampaignRecommendedCategoriesQuery } from '@/store/campaign/campaign.api';
import { selectCampaignAnalysis, selectEditAllScenarios, selectScenarios, selectSelectedScenario } from '@/store/campaign/campaign.selector';
import { getColumnStateFromFilters, getFiltersFromColumnState } from '@/utils/filters';
import { ArrowsPointingOutIcon, FunnelIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { ColumnFiltersState, FilterFnOption, Row } from '@tanstack/react-table';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitOptions, useParams, useSubmit } from 'react-router-dom';
import SelectAllScenarios from '../SelectAllScenarios';
import useOverviewColumns from './columns';
import { createScenarioUrl, ERROR_REASONS_MAP, getFailedUrlsOverviewTableData, getOverviewTableData } from '@/utils/campaigns';
import { AppDispatch } from '@/store/store';
import {
  setEveryScenarioUrlCloseToGap,
  setEveryScenarioUrlLinksToBuild,
  setUrlCloseToGap,
  setUrlLinksToBuild,
  updateAllScenariosUrls,
  updateSelectedScenarioUrls,
  getCampaignAnalysisRecommended,
  updateScenario,
} from '@/store/campaign/campaign.slice';
import { selectCompany } from '@/store/company/company.selector';
import { selectTablesConfig } from '@/store/app/app.selector';
import { useSteps } from '@/hooks/steps';
import { useCreateURLAudienceMutation } from '@/store/poc/poc.api';
import cn from '@/utils/style';
import Bugsnag from '@bugsnag/js';

const OVERVIEW_TABLE_HEADERS_MAP: Record<string, string> = {
  closeToGap: 'Close to Gap',
  scenarioLinksToBuild: 'Links to Build',
  targetPage: 'Target Page',
  totalVolume: 'Search Volume',
  difficulty: 'Difficulty (SEMR)',
  campaignRank: 'Campaign Rank',
  pageAuthorityDiff: 'Page Authority Diff',
  linksRootDomainDiff: 'Links / Domain',
  lrdGap: 'Domain Gap',
  volumeGapRatio: 'Gap Index',
  backlinkDADistribution: 'Backlink DA Distribution',
  ageAverage: 'Link Age (mo. Avg)',
  velocityDiff_6months: 'Velocity Diff',
  contextRelevanceScoreAvg: 'Link Relevance',
  anchorTextBuckets: 'Anchor Text Buckets',
  serpFeaturesByKeyword: 'SERP Features',
};

const COLUMN_FILTERS: Partial<AnalysisColumnFilters> = {
  totalVolume: NUMERIC_FILTERS_OPTIONS,
  difficulty: NUMERIC_FILTERS_OPTIONS,
  campaignRank: NUMERIC_FILTERS_OPTIONS,
  pageAuthorityDiff: NUMERIC_FILTERS_OPTIONS,
  linksRootDomainDiff: NUMERIC_FILTERS_OPTIONS,
  lrdGap: NUMERIC_FILTERS_OPTIONS,
  ageAverage: NUMERIC_FILTERS_OPTIONS,
};

const SORT_OPTIONS: Array<OptionType<SortingTypes>> = [
  {
    label: 'A-Z',
    value: 'asc',
  },
  {
    label: 'Z-A',
    value: 'desc',
  },
];

const overviewGlobalFilter = (row: Row<AnalysisData>, _columnId: keyof AnalysisData, filterValue: string) => {
  return row.original.targetPage.includes(filterValue);
};

interface OverviewTableProps {
  fullscreen?: boolean;
  toggle?: (value: boolean) => void;
}

const OverviewTable: FC<OverviewTableProps> = ({ fullscreen, toggle }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const submit = useSubmit();
  const dispatch = useDispatch<AppDispatch>();

  const { value: isSuccessCreateLCRModalOpen, toggle: toggleSuccessCreateLCRModal } = useToggle();
  const [createURLAudience] = useCreateURLAudienceMutation();

  const scenarios = useSelector(selectScenarios);
  const campaignAnalysis = useSelector(selectCampaignAnalysis);
  const selectedScenario = useSelector(selectSelectedScenario);
  const editAllScenarios = useSelector(selectEditAllScenarios);
  const company = useSelector(selectCompany);
  const tableConfig = useSelector(selectTablesConfig);

  const selectedScenarioHasBudget = Boolean(selectedScenario?.budget);

  const { nextStep } = useSteps();
  const submitAction: SubmitOptions = useMemo(() => ({ method: 'post', action: `/campaigns/${campaignId}/analysis` }), [campaignId]);
  const { data: campaign } = useGetCampaignQuery({ campaignId });

  const hasFailedUrls = useMemo(() => campaign && campaign.last_run?.failed_urls.length, [campaign]);

  const [sorting, setSorting] = useState<SortingTypes>('asc');
  const { value: recommendWithAiModalOpen, toggle: setRecommendWithAiModalOpen } = useToggle();
  const [recommendedFetchInProgress, setRecommendedFetchInProgress] = useState(false);
  const { data: configData } = useGetCampaignConfigQuery({ campaignId: campaignId });
  const { data: recommendedCategoriesData, isFetching: recommendedCategoriesIsFetching } = useGetCampaignRecommendedCategoriesQuery(
    { campaignId: campaignId },
    { skip: !recommendWithAiModalOpen || !selectedScenarioHasBudget || true },
  );
  const [recommendedCategoriesSelected, setRecommendedCategoriesSelected] = useState<Array<string>>([]);

  const failedScenarioUrls = useMemo(() => {
    if (!campaign || !campaign.last_run) {
      return [];
    }

    const urls = campaign.last_run.errors
      .filter((url) => url.keyword === null)
      .map((u) => {
        const { url } = u;
        return createScenarioUrl({ url_id: url.id, target_page: url.url });
      });

    return urls;
  }, [campaign]);

  const { value: isFiltersModalOpen, open: openFiltersModal, close: closeFiltersModal } = useToggle({});
  const { value: isFullScreenModalOpen, toggle: toggleFullScreenModal } = useToggle();
  const [columnFiltersState, setColumnFiltersState] = useState<ColumnFiltersState>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const filtersFromColumnState = useMemo(() => getFiltersFromColumnState(columnFiltersState) as Array<AnalysisFilterValue>, [columnFiltersState]);
  const customAnalysisData = useMemo(() => {
    if (!hasFailedUrls || !campaign || !campaign.last_run) {
      return getOverviewTableData({ scenarioUrls: selectedScenario?.urls, campaignAnalysis: campaignAnalysis });
    }

    const errors = campaign.last_run.errors.reduce((acc, error) => {
      const { url, keyword, type } = error;

      if (keyword) {
        return acc;
      }

      const exists = acc.find((u) => {
        return u.url.url === url.url;
      });

      if (exists) {
        return acc;
      }

      acc.push({ ...error, message: ERROR_REASONS_MAP[type] });

      return acc;
    }, [] as RunError[]);

    const failedUrls = getFailedUrlsOverviewTableData(errors);

    return getOverviewTableData({ scenarioUrls: [...(selectedScenario?.urls || []), ...failedScenarioUrls], campaignAnalysis: [...campaignAnalysis, ...failedUrls] });
  }, [selectedScenario, campaignAnalysis, hasFailedUrls, campaign, failedScenarioUrls]);

  const columns = useOverviewColumns({
    hasAnchorsText: Boolean(configData?.data.has_anchor_texts),
    hasKeywords: Boolean(configData?.data.keywords?.length),
  });

  const [table] = useTable({
    data: customAnalysisData,
    columns,
    defaultSorting: tableConfig['campaigns'].sorting || [{ id: 'totalVolume', desc: true }],
    tableCustomOptions: ['allowFilters', 'allowSelect', 'allowSort'],
    tableOptions: {
      onColumnFiltersChange: setColumnFiltersState,
      onGlobalFilterChange: setGlobalFilterValue,
      globalFilterFn: overviewGlobalFilter as FilterFnOption<AnalysisData>,
      initialState: {
        columnPinning: {
          left: ['closeToGap', 'scenarioLinksToBuild'],
        },
      },
      state: {
        columnVisibility: tableConfig['overview'].visibility,
        columnFilters: columnFiltersState,
        globalFilter: globalFilterValue,
      },
      meta: {
        toggleUrl: (url: ScenarioUrl, isEditAllScenarios: boolean) => {
          if (isEditAllScenarios) {
            return dispatch(updateAllScenariosUrls({ url }));
          }

          dispatch(updateSelectedScenarioUrls({ url, company }));
        },
        setUrlLinksToBuild: ({ url_id, links_to_build, url }: { url_id: number; links_to_build: number; url: ScenarioUrl }) => {
          if (editAllScenarios) {
            return dispatch(setEveryScenarioUrlLinksToBuild({ url_id, links_to_build, company, url }));
          }

          dispatch(setUrlLinksToBuild({ url_id, links_to_build, company }));
        },
        setUrlCloseToGap: ({ url_id, close_to_gap, url }: { url_id: number; close_to_gap: number; url?: ScenarioUrl }) => {
          if (editAllScenarios) {
            return dispatch(setEveryScenarioUrlCloseToGap({ url_id, close_to_gap, company, url }));
          }

          dispatch(setUrlCloseToGap({ url_id, close_to_gap, company }));
        },
        onBeforeNavigate: () => {
          submit(
            {
              scenarios: JSON.stringify(scenarios),
              isDraft: 'true',
              nextStep,
            },
            submitAction,
          );
        },
        createLCR: async (url: string) => {
          await createURLAudience({ url });
          toggleSuccessCreateLCRModal(true);
        },
      },
    },
  });

  const handleSearch = useCallback((value: string) => setGlobalFilterValue(value), []);

  const handleConfirm = useCallback(
    (values: AnalysisFiltersFormValues) => {
      setColumnFiltersState(getColumnStateFromFilters(values.filters));
      closeFiltersModal();
    },
    [closeFiltersModal],
  );

  const handleSortChange = (value: SortingTypes) => setSorting(value);

  const handleFilterRemove = useCallback((filter: AnalysisFilterValue) => {
    setColumnFiltersState((oldState) => oldState.filter((i) => i.id !== filter.field));
  }, []);

  const handleToggleFullScreenModal = useCallback(() => {
    if (isFullScreenModalOpen) {
      return toggleFullScreenModal(false);
    }
    if (!toggle) {
      return toggleFullScreenModal(true);
    }
    if (fullscreen) {
      return toggle(false);
    }
    return toggle(true);
  }, [fullscreen, isFullScreenModalOpen, toggle, toggleFullScreenModal]);

  if (!selectedScenario || scenarios.length === 0) {
    return null;
  }

  const getRecommendedScenarios = async () => {
    setRecommendedFetchInProgress(true);

    try {
      const {
        payload: {
          data: { scenarios: recommendedScenarios },
        },
      } = await dispatch(getCampaignAnalysisRecommended({ id: campaignId, scenarioId: selectedScenario.id, categories: recommendedCategoriesSelected }));

      return recommendedScenarios.reverse();
    } catch (e) {
      Bugsnag.notify(new Error('getCampaignAnalysisRecommended() failed!'), (event) => event.addMetadata('metadata', { campaignId, recommendedCategoriesSelected }));
    } finally {
      setRecommendedFetchInProgress(false);
    }

    return [];
  };

  const handleCompleteWithAiClick = async () => {
    const recommendedScenarios = await getRecommendedScenarios();

    if (!recommendedScenarios.length) {
      return false;
    }

    for (const scenarioJson of recommendedScenarios) {
      const scenarioOnState = scenarios.find(({ id }) => id === scenarioJson.id);

      if (!scenarioOnState) {
        continue;
      }

      const urls = scenarioJson.urls.map((url: { id: number; links_to_build: number; lrd_gap: number }) => {
        const { links_to_build: linksToBuild, lrd_gap: lrdGap, id: urlId } = url;

        const scenarioUrl = scenarioOnState.urls.find((i) => i.url_id === urlId) || createScenarioUrl({});

        const { targetPage, totalVolume } = campaignAnalysis.find((i) => urlId === i.targetPageId) || {};

        return { ...scenarioUrl, close_to_gap: null, links_to_build: linksToBuild, lrd_gap: lrdGap, target_page: targetPage, total_volume: totalVolume, url_id: urlId };
      });

      dispatch(updateScenario({ scenario: { ...scenarioOnState, urls, cost_per_link: scenarioJson.cost_per_link }, company: null }));
    }

    setRecommendWithAiModalOpen(false);
  };

  const handleCheckboxChange = (category: string) => {
    setRecommendedCategoriesSelected((i) => {
      if (i.includes(category)) {
        return i.filter((c) => c !== category);
      }

      if (i.length > 2) {
        i.shift();
      }

      return [...i, category];
    });
  };

  const hasCategories = recommendedCategoriesData && recommendedCategoriesData.data.length > 1;
  const aiButtonDisabled = campaign?.status === 'kicked_off';

  return (
    <div className='mb-2 flex flex-col items-start gap-2 xl:gap-4'>
      <div className={cn('w-full rounded-xl bg-white p-5', !fullscreen && 'pb-8')}>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-4'>
            <SearchBarDebounce onChange={handleSearch} inputClassName='w-[25.75rem] h-10' />
            <button onClick={openFiltersModal} className='rounded-lg border p-2'>
              <FunnelIcon className='w-5' />
            </button>
            {scenarios.length > 0 && (
              <Button
                disabled={aiButtonDisabled}
                className={cn('mr-auto inline-flex rounded-lg bg-indigo-500 p-2 px-4 shadow-md shadow-indigo-500/50 hover:bg-indigo-700', aiButtonDisabled && 'opacity-40')}
                variant={null}
                onClick={() => setRecommendWithAiModalOpen(true)}
              >
                <p className='mr-2'>Get AI Recommendation</p>
                <SparklesIcon className='mr-1 w-5' />
              </Button>
            )}
            <Modal
              isOpen={recommendWithAiModalOpen}
              title='Recommend with AI'
              footer={
                !selectedScenarioHasBudget && (
                  <Button onClick={() => setRecommendWithAiModalOpen(false)} variant='outline-light'>
                    Cancel
                  </Button>
                )
              }
              loadingComponent={
                <div className='min-w-[30rem]'>
                  <Skeleton className='h-[35rem]'>
                    <div className='flex'>
                      <p className='mx-auto mt-8'>Fetching categories...</p>
                    </div>
                  </Skeleton>
                </div>
              }
              isLoading={recommendedCategoriesIsFetching}
              onConfirm={selectedScenarioHasBudget ? handleCompleteWithAiClick : undefined}
              onClose={() => setRecommendWithAiModalOpen(false)}
              confirmButtonText='OK'
              buttonsLoading={recommendedFetchInProgress}
              className='max-w-modal'
            >
              <div className='flex flex-col gap-2'>
                <p className='mb-2'>We will select a set of URLs and the number of links to build for each one, later you will be able to edit AI suggestion manually.</p>
                {!selectedScenarioHasBudget && (
                  <div className='mb-2 rounded-lg bg-slate-100 p-3 font-bold text-red-500'>
                    <p>You need to set budget for this scenario first.</p>
                  </div>
                )}
                {hasCategories && <p className='mb-1'>Please choose your most relevant categories:</p>}
                {hasCategories &&
                  recommendedCategoriesData.data.map((i) => {
                    return (
                      <div key={i} className='flex w-full flex-row gap-2'>
                        <Checkbox checked={recommendedCategoriesSelected.includes(i)} onChange={() => handleCheckboxChange(i)} readOnly className='border-gray-400' />
                        <Label className='capitalize text-gray-500'>{i.replace(/_/g, ' ')}</Label>
                      </div>
                    );
                  })}
              </div>
            </Modal>
          </div>
          <div className='flex items-center gap-4'>
            <SelectFilter label='Sort URLs by' options={SORT_OPTIONS} value={sorting} onChange={handleSortChange} className='flex-nowrap' withMarker />
            <TableVisibilityMenu table={table} name={'overview'} labels={OVERVIEW_TABLE_HEADERS_MAP} />
            {!fullscreen && (
              <button onClick={handleToggleFullScreenModal}>
                <ArrowsPointingOutIcon className='w-7 duration-300 hover:scale-75 active:opacity-70' />
              </button>
            )}
          </div>
        </div>
        <FiltersPills filters={filtersFromColumnState} onFilterRemove={handleFilterRemove} />
        <div className='flex items-center justify-between  rounded-md bg-gray-200 p-2'>
          <SelectAllScenarios />
        </div>
        <VirtualizedTable
          table={table}
          name='campaigns'
          loadingText='Loading data...'
          cellStyle='pt-10 pb-2 px-2 border-b border-gray-200'
          rowStyle='last-child:border-b-0'
          className='mb-5'
          tableClass='shadow-none'
          containerClass={cn('2xl:max-h-[calc(70vh-12rem)] max-h-[calc(60vh-12rem)] -mb-5', fullscreen && '2xl:max-h-[80vh] max-h-[70vh]')}
          headerClass='text-sm'
          itemHeight={86.5}
        />
        <SuccessModal isOpen={isSuccessCreateLCRModalOpen} message='Your LCR has been successfully created' onConfirm={() => toggleSuccessCreateLCRModal(false)} />
      </div>
      {isFullScreenModalOpen && (
        <Modal isOpen={isFullScreenModalOpen} className='h-fit w-[90vw]' title='Analysis' onClose={() => toggleFullScreenModal(false)}>
          <OverviewTable fullscreen={isFullScreenModalOpen} toggle={toggleFullScreenModal} />
        </Modal>
      )}
      {isFiltersModalOpen && (
        <FiltersModal isOpen={isFiltersModalOpen} onClose={closeFiltersModal} columnFilters={COLUMN_FILTERS} initialValue={filtersFromColumnState} onConfirm={handleConfirm} />
      )}
    </div>
  );
};

export default OverviewTable;
