import { FormikProvider, useFormik } from 'formik';
import Modal, { ModalProps } from '../Modal';
import TextInputField from '../TextInputField';
import Button from '../Button';
import * as yup from 'yup';
import { UrlValidator } from '@/utils/urls';
import { BUSINESS_MODELS, BusinessModel, BusinessModelValue } from './utils';
import BusinessModelCard from './BusinessModelCard';

const createLCRFormSchema = yup.object({
  url_name: UrlValidator.min(1),
  business_model: yup.string().oneOf(['b2b', 'b2c', 'both']).required('A type of business is required'),
});

type CreateLCRFormValues = {
  url_name: string;
  business_model: BusinessModelValue | null;
};

const CreateLCRModal = ({ isOpen, onClose }: ModalProps) => {
  const formik = useFormik<CreateLCRFormValues>({
    initialValues: {
      url_name: '',
      business_model: null,
    },
    onSubmit: () => {},
    validationSchema: createLCRFormSchema,
  });

  const renderBuisnessModels = (model: BusinessModel) => (
    <BusinessModelCard
      model={model}
      key={model.value}
      onClick={() => formik.setFieldValue('business_model', model.value)}
      isSelected={formik.values.business_model === model.value}
    />
  );

  return (
    <Modal
      isOpen={isOpen}
      className='w-modal'
      title='Create LCR'
      onClose={onClose}
      footer={
        <div className='text-end'>
          <Button disabled={!formik.isValid || !formik.dirty} onClick={() => formik.handleSubmit()}>
            Create ICP Profile
          </Button>
        </div>
      }
    >
      <FormikProvider value={formik}>
        <div className='mb-4'>
          <p className='mb-2'>Enter your business URL</p>
          <TextInputField className='border border-slate-300 bg-slate-100' placeholder='Enter URL' name='url_name' />
        </div>
        <p className='mb-2'>Choose the business model for your company</p>
        <div className='flex gap-4'>{BUSINESS_MODELS.map(renderBuisnessModels)}</div>
      </FormikProvider>
    </Modal>
  );
};

export default CreateLCRModal;
