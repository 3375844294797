import cn from '@/utils/style';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { FC, MouseEventHandler, PropsWithChildren } from 'react';

type Variant = 'primary' | 'secondary' | 'warning' | 'outline-light' | 'outline-primary' | 'dashed-primary';

interface ButtonProps extends PropsWithChildren {
  id?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant?: Variant | null;
  disabled?: boolean;
  isLoading?: boolean;
  type?: 'button' | 'submit';
  className?: string;
  loadingText?: string;
}

const Button: FC<ButtonProps> = ({ children, onClick, variant = 'primary', disabled = false, isLoading = false, type = 'button', className, id, loadingText }) => {
  return (
    <button
      id={id}
      className={cn(
        'h-fit cursor-pointer rounded-lg px-6 py-2 font-medium text-white disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-200 disabled:text-slate-400',
        variant === 'primary' && 'bg-sky-600 hover:bg-sky-700 active:bg-sky-800',
        variant === 'secondary' && 'border border-slate-300 bg-slate-100 text-slate-800 hover:bg-slate-200 active:bg-slate-300',
        variant === 'warning' && 'bg-red-500 hover:bg-red-600 active:bg-red-700',
        variant === 'outline-light' && 'border border-slate-300 bg-white text-gray-700 hover:border-slate-400 active:border-slate-500',
        variant === 'outline-primary' && 'border border-blue-500 bg-white text-blue-500',
        variant === 'dashed-primary' && 'border border-dashed border-sky-600 bg-sky-100 font-light text-black',
        isLoading && 'flex cursor-not-allowed border text-slate-400 hover:border-slate-200 active:border-slate-200',
        className,
      )}
      type={type}
      onClick={isLoading ? undefined : onClick}
      disabled={isLoading || disabled}
    >
      {isLoading && <ArrowPathIcon className='mx-auto w-5 animate-spin opacity-90' />}
      {!isLoading && children}
      {isLoading && loadingText && <p className='mx-2'>{loadingText}</p>}
    </button>
  );
};

export default Button;
