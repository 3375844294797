import { Skeleton } from '@/components';
import { FC } from 'react';

const URLPageSkeleton: FC = () => (
  <div className='w-full'>
    <Skeleton className='h-[calc(100vh-11rem)] w-full animate-pulse' />
  </div>
);

export default URLPageSkeleton;
