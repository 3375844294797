import Modal, { ModalProps } from '../Modal';

interface WarningModalProps extends ModalProps {
  message?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const WarningModal = ({
  message = 'Are you sure you want to leave current selection? All progress will be lost',
  onClose,
  isOpen,
  onConfirm,
  children,
  ...props
}: WarningModalProps) => {
  return (
    <Modal isOpen={isOpen} title='Warning!' onClose={onClose} onConfirm={onConfirm} {...props}>
      <p>{message}</p>
      {children}
    </Modal>
  );
};

export default WarningModal;
