import { Button, FiltersModal, FiltersPills, SearchBarDebounce, VirtualizedTable } from '@/components';
import { NUMERIC_FILTERS_OPTIONS, STRING_FILTERS_OPTIONS } from '@/constants/defaultValues';
import { useTable } from '@/hooks/table';
import { useToggle } from '@/hooks/toggle';
import { selectTablesConfig } from '@/store/app/app.selector';
import { useGetCampaignConfigQuery, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { selectCampaignAnalysis, selectScenarios } from '@/store/campaign/campaign.selector';
import { setAllocations, updateScenarioUrls } from '@/store/campaign/campaign.slice';
import { selectCompany } from '@/store/company/company.selector';
import { AppDispatch } from '@/store/store';
import { getColumnStateFromFilters, getFiltersFromColumnState } from '@/utils/filters';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { ColumnFiltersState, FilterFnOption, Row } from '@tanstack/react-table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ContinueWithoutAllocationsModal from '../ContinueWithoutAllocationsModal';
import ScenarioKeywordsModal from '../ScenarioKeywordsModal';
import { getScenarioUrlKeywords, getUserSelectionAllocations } from '@/utils/campaigns';
import priorityKeywordsColumns from './columns';
import { updateCampaignScenarioMutation } from '@/store/reducers';
import { unwrapResult } from '@reduxjs/toolkit';

interface PriorityKeywordsContentProps {
  onComplete: () => void;
}

const COLUMN_FILTERS: Partial<ScenarioUrlsFilters> = {
  links_to_build: NUMERIC_FILTERS_OPTIONS,
  target_page: STRING_FILTERS_OPTIONS,
  total_volume: NUMERIC_FILTERS_OPTIONS,
  lrd_gap: NUMERIC_FILTERS_OPTIONS,
};

const priorityKeywordsGlobalFilter = (row: Row<ScenarioUrl>, _columnId: keyof ScenarioUrl, filterValue: string) => {
  return row.original.target_page.includes(filterValue);
};

const PriorityKeywordsContent: FC<PriorityKeywordsContentProps> = ({ onComplete }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const dispatch = useDispatch<AppDispatch>();
  const company = useSelector(selectCompany);
  const scenarios = useSelector(selectScenarios);
  const tableConfig = useSelector(selectTablesConfig);
  const campaignAnalysis = useSelector(selectCampaignAnalysis);

  const [updateCampaignConfig] = useSaveCampaignConfigMutation();
  const { data: configData, isLoading: configLoading } = useGetCampaignConfigQuery({ campaignId });

  const { value: isFiltersModalOpen, toggle: toggleFiltersModal } = useToggle();
  const { value: isContinueWithoutAllocationsModalOpen, toggle: toggleContinueWithoutAllocationsModal } = useToggle();

  const [selectedUrl, setSelectedUrl] = useState<ScenarioUrl | null>(null);
  const [columnFiltersState, setColumnFiltersState] = useState<ColumnFiltersState>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [keywordsAllocations, setKeywordsAllocations] = useState<AllocationKeyword[]>([]);

  const config = useMemo(() => (configData ? configData.data : {}), [configData]);
  const approvedScenario = useMemo(() => scenarios.find((s) => s.is_approved), [scenarios]);
  const filtersFromColumnState = useMemo(() => getFiltersFromColumnState(columnFiltersState) as Array<ScenarioUrlFilterValue>, [columnFiltersState]);
  const hasAllocations = useMemo(() => (approvedScenario?.urls || []).some((u) => u.is_allocated), [approvedScenario]);
  const userSelectionMap = useMemo(() => getUserSelectionAllocations(config.keywords || []), [config.keywords]);
  const defaultKeywords = useMemo<AllocationKeyword[]>(
    () => getScenarioUrlKeywords({ campaignAnalysis, userSelectionMap, scenarioUrls: approvedScenario?.urls || [] }),
    [campaignAnalysis, userSelectionMap, approvedScenario?.urls],
  );

  useEffect(() => {
    // set local state to change on modal inputs and not affect the default values in case of cancel
    setKeywordsAllocations(defaultKeywords.filter(({ targetPage }) => targetPage === selectedUrl?.target_page));
  }, [defaultKeywords, selectedUrl?.target_page]);

  const [table] = useTable({
    data: approvedScenario?.urls || [],
    columns: priorityKeywordsColumns(),
    tableCustomOptions: ['allowFilters', 'allowSort'],
    defaultSorting: tableConfig['priorityKeywordsContent'].sorting,
    tableOptions: {
      onGlobalFilterChange: setGlobalFilterValue,
      onColumnFiltersChange: setColumnFiltersState,
      globalFilterFn: priorityKeywordsGlobalFilter as FilterFnOption<ScenarioUrl>,
      state: {
        globalFilter: globalFilterValue,
        columnFilters: columnFiltersState,
      },
      meta: {
        handleAllocation: (url: ScenarioUrl) => setSelectedUrl(url),
      },
    },
  });

  const handleConfirm = useCallback(
    (values: ScenarioUrlFiltersFormValue) => {
      setColumnFiltersState(getColumnStateFromFilters(values.filters));
      toggleFiltersModal(false);
    },
    [toggleFiltersModal],
  );

  const markAsAllocated = async () => {
    const configKws = (configData?.data.keywords as AllocationKeyword[]) || [];

    const newKws = Object.values(
      [...configKws, ...keywordsAllocations].reduce((acc, kw) => {
        acc[kw.id] = kw;
        return acc;
      }, {} as Record<number, AllocationKeyword>),
    );

    const urls = (approvedScenario?.urls || []).map((url) => (selectedUrl?.target_page === url.target_page ? { ...url, is_allocated: true } : url));

    if (approvedScenario) {
      const updatedUrls = urls.map((url) => {
        const targetPage = newKws.filter((kw) => kw.targetPage === url.target_page);

        const kwsToAdd = targetPage.filter((kw) => {
          return !(url.defaults || []).find((d) => d.keyword_id === kw.id);
        });

        const transformed = kwsToAdd.map((kw) => ({ keyword_id: kw.id, anchors_total: kw.anchorsTotal }));

        return { ...url, defaults: [...(url.defaults || []), ...transformed] };
      });

      const resp = await dispatch(
        updateCampaignScenarioMutation({
          campaignId,
          scenario: {
            ...approvedScenario,
            urls: updatedUrls,
          } as Scenario,
        }),
      );

      const result = unwrapResult(resp);

      dispatch(updateScenarioUrls({ scenarioId: result.id, urls: result.urls, company }));
    }
  };

  const handleConfirmKwModal = async () => {
    setSelectedUrl(null);
    await markAsAllocated();
  };

  const handleConfirmWithoutAllocations = async () => {
    toggleContinueWithoutAllocationsModal(false);
    await updateCampaignConfig({ campaignId: campaignId, config: { keywords: defaultKeywords } }).unwrap();
    onComplete();
  };

  const handleNextStep = async () => {
    if (!hasAllocations) {
      return toggleContinueWithoutAllocationsModal(true);
    }

    if (!approvedScenario) {
      return;
    }

    const allocatedMap = new Map<number, AllocationKeyword>();
    for (const kw of keywordsAllocations) {
      allocatedMap.set(kw.id, kw);
    }

    const keywords = defaultKeywords.map((defaultKw) => {
      const allocatedKw = allocatedMap.get(defaultKw.id);
      return allocatedKw ? allocatedKw : defaultKw;
    });

    await updateCampaignConfig({ campaignId, config: { keywords } }).unwrap();
    dispatch(setAllocations(keywords));
    onComplete();
  };

  const handleFilterRemove = useCallback((filter: ScenarioUrlFilterValue) => {
    setColumnFiltersState((oldState) => oldState.filter((i) => i.id !== filter.field));
  }, []);

  return (
    <div className='w-full'>
      <div className='flex items-center gap-4'>
        <SearchBarDebounce onChange={(value) => setGlobalFilterValue(value)} className='w-full' inputClassName='w-full' />
        <div className='flex cursor-pointer flex-row flex-nowrap items-center gap-2 text-sky-600' onClick={() => toggleFiltersModal(true)}>
          <p className='text-lg font-bold'>Filters</p>
          <FunnelIcon className='w-6' />
        </div>
      </div>
      <FiltersPills filters={filtersFromColumnState} onFilterRemove={handleFilterRemove} />
      <VirtualizedTable
        table={table}
        name='priorityKeywordsContent'
        loadingText='Loading data...'
        headerClass='text-sm px-1'
        itemHeight={65}
        className='max-h-[calc(60vh-10rem)]'
      />

      <div className='mb-2 mt-4 flex justify-end text-right'>
        <Button onClick={handleNextStep}>Next Step</Button>
      </div>

      {selectedUrl && (
        <ScenarioKeywordsModal
          isOpen
          className='w-modal-xl'
          onConfirm={handleConfirmKwModal}
          onClose={() => setSelectedUrl(null)}
          isLoading={configLoading}
          keywordsAllocations={keywordsAllocations}
          setKeywordsAllocations={setKeywordsAllocations}
          urlId={selectedUrl.url_id}
          userSelectionMap={userSelectionMap}
          maxAnchors={selectedUrl?.links_to_build || 0}
          allowZeroTotalAnchors
        />
      )}

      {isFiltersModalOpen && (
        <FiltersModal
          isOpen={isFiltersModalOpen}
          onClose={() => toggleFiltersModal(false)}
          columnFilters={COLUMN_FILTERS}
          initialValue={filtersFromColumnState}
          onConfirm={handleConfirm}
        />
      )}

      {isContinueWithoutAllocationsModalOpen && (
        <ContinueWithoutAllocationsModal isOpen={true} onClose={() => toggleContinueWithoutAllocationsModal(false)} onConfirm={handleConfirmWithoutAllocations} />
      )}
    </div>
  );
};

export default PriorityKeywordsContent;
