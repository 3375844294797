import { countDecimals, round } from './numbers';
import Bugsnag from '@bugsnag/js';

const CAMPAIGN_FIELDS_TITLES: Record<AllowedFilters, string> = {
  name: 'Name',
  status: 'Status',
  keyword_count: 'KWs',
  url_count: 'URLs',
  failed_urls: 'Failed URLs',
  failed_kws: 'Failed KWs',
  country: 'Country',
  spreadsheet_id: 'Sheets',
};

const KEYWORDS_FIELDS_TITLES: Record<AnalysisKeywordFields, string> = {
  keyword_text: 'Keyword',
  url: 'URL',
  rank: 'Rank',
  volume: 'Volume',
  difficulty: 'Difficulty',
};

const ANALYSIS_FIELDS_TITLES: Record<AnalysisFilters, string> = {
  campaignRank: 'Average Rank',
  difficulty: 'Difficulty',
  targetPage: 'Target Page',
  totalVolume: 'Total Volume',
  pageAuthorityDiff: 'Page Authority',
  linksRootDomainDiff: 'Links Root Domain',
  lrdGap: 'Domain Gap',
  ageAverage: 'Age Average',
};

const REFINE_KEYWORD_FILTER_TITLE: Record<RefineKeywordsFilters, string> = {
  text: 'Keyword',
  target_page: 'Target Page',
  campaign_rank: 'Campaign Rank',
  search_volume: 'Search Volume',
  difficulty: 'Difficulty',
  domain_gap: 'Domain Gap',
};

const REFINE_COMPETITOR_FIELDS_TITLES: Record<RefineCompetitorsFields, string> = {
  domain: 'Domain',
  urls: 'URLs',
  ranking_pages_number: '# of Ranking Pages',
  number_of_keywords: '# of KWs',
  avg_da: 'DA',
  avg_pa: 'Page Authority',
  ref_domains: 'Avg. Ref. Domains',
  backlinks: 'Avg. Backlinks',
  target_pages: 'Target URLs',
};

const SCENARIO_PAGES_FIELDS_TITLES: Record<ScenarioPagesFields, string> = {
  volume: 'Volume',
  gap: 'Gap',
  targetUrl: 'Target URL',
};

const CAMPAIGN_KEYWORDS_FIELDS_TITLES: Record<CampaignKeywordFields, string> = {
  keyword_text: 'Keyword',
  url: 'URL',
  campaignRank: 'Average Rank',
  totalVolume: 'Volume',
  difficulty: 'Difficulty',
  campaignRankDiff: 'Rank Diff',
  pageAuthorityDiff: 'Page Authority',
  linksRootDomainDiff: 'Links/Domain',
  rootDomainDiff: 'Domain Gap',
};

const URL_ANCHOR_TEXT_FIELDS_TITLES: Record<UrlAnchorTextFields, string> = {
  'url.target_page': 'URL',
  match_type: 'Match Type',
  keyword_text: 'Keyword',
  value: 'Anchor',
};

const SCENARIO_URL_FIELDS_TITLES: Record<ScenarioUrlFields, string> = {
  links_to_build: 'Links to Build',
  target_page: 'Target Page',
  total_volume: 'Volume',
  lrd_gap: 'Domain Gap',
};

const MAP_FIELD_TO_TITLE: Record<EveryFilter, string> = {
  ...REFINE_KEYWORD_FILTER_TITLE,
  ...CAMPAIGN_FIELDS_TITLES,
  ...KEYWORDS_FIELDS_TITLES,
  ...ANALYSIS_FIELDS_TITLES,
  ...REFINE_COMPETITOR_FIELDS_TITLES,
  ...SCENARIO_PAGES_FIELDS_TITLES,
  ...CAMPAIGN_KEYWORDS_FIELDS_TITLES,
  ...URL_ANCHOR_TEXT_FIELDS_TITLES,
  ...SCENARIO_URL_FIELDS_TITLES,
};

const getScenarioProjectedROI = (roi: ROISummary, projectLength: number) => {
  if (projectLength === 3) {
    return roi.at_end_of_project;
  }

  if (projectLength === 6) {
    return roi.at_end_of_project_plus_6_months;
  }

  return roi.at_end_of_project_plus_12_months;
};

const getBudgetValue = (budget?: string) => (budget && budget.trim() === '' ? undefined : Number(budget));

const getRecommendedMatch = ({ data, linksToBuild }: { data?: AnalysisData; linksToBuild: number }) => {
  const output = {
    recommended: { exact_match: 0, partial_match: 0, generic_match: 0 },
    totals: {
      cl: { exact_match: 0, partial_match: 0, generic_match: 0 },
      co: { exact_match: 0, partial_match: 0, generic_match: 0 },
    },
  };

  if (!data) {
    return output;
  }

  const { anchorTextBuckets, competitor, backlinksTotal, anchorTextBucketsCount } = data;

  output.totals = {
    cl: {
      exact_match: anchorTextBucketsCount.exactMatch,
      partial_match: anchorTextBucketsCount.partialMatch,
      generic_match: anchorTextBucketsCount.other,
    },
    co: {
      exact_match: competitor.anchorTextBucketsCount.exactMatch,
      partial_match: competitor.anchorTextBucketsCount.partialMatch,
      generic_match: competitor.anchorTextBucketsCount.other,
    },
  };

  const clientExactMatchesRatio = (anchorTextBuckets.exactMatch || 0) / 100;
  const competitorExactMatchesRatio = (competitor.anchorTextBuckets.exactMatch || 0) / 100;

  const clientPartialMatchesRatio = (anchorTextBuckets.partialMatch || 0) / 100;
  const competitorPartialMatchesRatio = (competitor.anchorTextBuckets.partialMatch || 0) / 100;

  const additionalExactAnchorsNeeded = Math.round(competitorExactMatchesRatio * (backlinksTotal + linksToBuild) - clientExactMatchesRatio * backlinksTotal);
  const additionalPartialAnchorsNeeded = Math.round(competitorPartialMatchesRatio * (backlinksTotal + linksToBuild) - clientPartialMatchesRatio * backlinksTotal);

  output.recommended.partial_match = additionalPartialAnchorsNeeded < 0 ? 0 : additionalPartialAnchorsNeeded;

  if (output.recommended.partial_match >= linksToBuild) {
    return {
      recommended: { exact_match: 0, partial_match: linksToBuild, generic_match: 0 },
      totals: output.totals,
    };
  }

  output.recommended.exact_match = additionalExactAnchorsNeeded < 0 ? 0 : additionalExactAnchorsNeeded;

  if (output.recommended.partial_match + output.recommended.exact_match >= linksToBuild) {
    return {
      recommended: { exact_match: linksToBuild - output.recommended.partial_match, partial_match: output.recommended.partial_match, generic_match: 0 },
      totals: output.totals,
    };
  }

  return {
    recommended: { ...output.recommended, generic_match: linksToBuild - (output.recommended.partial_match + output.recommended.exact_match) },
    totals: output.totals,
  };
};

const getParsedAnalysisData = ({ data, scenario }: { data: AnalysisData[]; scenario: Scenario }) => {
  const groupedData = data.reduce((acc: Record<string, AnalysisData[]>, i) => {
    const key = `target_page_id|${i.targetPageId}`;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(i);

    return acc;
  }, {});

  // WIP, using % to calculate avgs is not a good idea

  const dataMap = Object.keys(groupedData).reduce((acc, key) => {
    const rowData = groupedData[key];

    acc.set(key, {
      ...rowData[0],
      anchorTextBuckets: {
        exactMatch: round(rowData.reduce((acc, i) => acc + i.anchorTextBuckets.exactMatch, 0) / rowData.length, 0),
        partialMatch: round(rowData.reduce((acc, i) => acc + i.anchorTextBuckets.partialMatch, 0) / rowData.length, 0),
        other: round(rowData.reduce((acc, i) => acc + i.anchorTextBuckets.other, 0) / rowData.length, 0),
      },
    });

    return acc;
  }, new Map());

  return scenario.urls.reduce((adUrls: Array<UrlAnchorTextTableColumns>, inputScenarioUrl) => {
    const scenarioUrl = createScenarioUrl(inputScenarioUrl);

    const row = dataMap.get(`target_page_id|${scenarioUrl.url_id}`);

    if (!row) {
      Bugsnag.notify(new Error('Missing row data at getParsedAnalysisData()'), (event) => event.addMetadata('metadata', { dataMap, scenarioUrl }));

      return adUrls;
    }

    const { recommended, totals } = getRecommendedMatch({ data: row, linksToBuild: scenarioUrl.links_to_build });

    const newUrl = {
      url_id: scenarioUrl.url_id,
      target_page: scenarioUrl.target_page,
      links_per_month: scenarioUrl.links_to_build,
      exact_match: scenarioUrl.anchor_text.exact,
      partial_match: scenarioUrl.anchor_text.partial,
      generic_match: scenarioUrl.anchor_text.generic,
      is_allocated: scenarioUrl.is_allocated || false,
      cl: {
        exact_match: row ? round(row.anchorTextBuckets.exactMatch, 2) : 0,
        partial_match: row ? round(row.anchorTextBuckets.partialMatch, 2) : 0,
        generic_match: row ? round(row.anchorTextBuckets.other, 2) : 0,
      },
      co: {
        exact_match: row ? round(row.competitor.anchorTextBuckets.exactMatch, 2) : 0,
        partial_match: row ? round(row.competitor.anchorTextBuckets.partialMatch, 2) : 0,
        generic_match: row ? round(row.competitor.anchorTextBuckets.other, 2) : 0,
      },
      recommended,
      totals: totals,
    };

    adUrls.push(newUrl);

    return adUrls;
  }, []);
};

const getUserSelectionAllocations = (keywords: CampaignConfigKeyword[]) =>
  keywords.reduce((acc: Map<number, CampaignConfigKeyword>, kw) => {
    acc.set(kw.id, kw);
    return acc;
  }, new Map());

const getScenarioUrlKeywords = ({
  campaignAnalysis,
  userSelectionMap,
  scenarioUrls,
}: {
  campaignAnalysis: AnalysisData[];
  userSelectionMap: Map<number, CampaignConfigKeyword>;
  scenarioUrls: ScenarioUrl[];
}) => {
  const defaultsMap = new Map<number, ScenarioUrlDefault>();
  const urls = scenarioUrls.flatMap(({ defaults }) => defaults || []);
  for (const kw of urls) {
    defaultsMap.set(kw.keyword_id, kw);
  }

  return campaignAnalysis.map((k) => {
    const userSelection = userSelectionMap.get(k.keyword.id);
    const defaultValue = defaultsMap.get(k.keyword.id);
    const anchorsTotal = userSelection ? userSelection.anchorsTotal : defaultValue ? defaultValue.anchors_total : 0;
    const priority = userSelection ? userSelection.priority : k.keyword.priority;

    return {
      id: k.keyword.id,
      keyword: k.keyword.text,
      targetPage: k.url,
      totalVolume: k.totalVolume,
      rootDomainDiff: round(k.rootDomain - k.competitor.rootDomain, 2),
      lrdGapKeyword: k.lrdGapKeyword,
      text_hash: k.keyword.text_hash,
      priority: priority,
      anchorsTotal: anchorsTotal,
    };
  });
};

const redistributeMatches = (total: number, matches: AnchorText) => {
  const resp = { ...matches };
  let assigned = 0;

  for (const key in matches) {
    if (total === assigned) continue;

    const typedKey = key as keyof AnchorText;

    const matchValue = matches[typedKey];
    if (assigned + matchValue <= total) {
      resp[typedKey] = matchValue;
      assigned += matchValue;
      continue;
    }

    if (assigned < total) {
      resp[typedKey] = total - assigned;
      assigned = total;
      continue;
    }

    resp[typedKey] = 0;
  }

  return resp;
};

const getEstimatedCampaignCost = ({ urlsQuantity, competitorsQuantity, keywordsQuantity }: { urlsQuantity: number; competitorsQuantity: number; keywordsQuantity: number }) => {
  const BACKLINKS_QUANTITY = 1000;
  const ANCHOR_TEXTS_QUANTITY = 1000;
  const COST_PER_RETRIVED_DATA_ROW = 0.000021;

  const totalUrls = urlsQuantity * competitorsQuantity * keywordsQuantity;
  const anchorsOverBacklinks = ANCHOR_TEXTS_QUANTITY / BACKLINKS_QUANTITY;
  const totalRowCost = (totalUrls + urlsQuantity) * COST_PER_RETRIVED_DATA_ROW * BACKLINKS_QUANTITY;

  const firstOperator = totalUrls * anchorsOverBacklinks * 0.01;
  const secondOperator = (totalUrls / BACKLINKS_QUANTITY) * 12;

  const total = firstOperator + secondOperator * 0.01 + totalRowCost;

  if (countDecimals(total) >= 3) {
    return round(total + 0.01, 2);
  }

  return total;
};

const getCampaignAnalysisStep = (campaign: CampaignJSON) => {
  if (campaign && campaign.status === 'kicked_off') {
    return 'summary';
  }

  if (campaign.config?.user_progress?.last_step) {
    return campaign.config.user_progress.last_step;
  }

  return 'analysis';
};

const getOverviewTableData = ({ scenarioUrls = [], campaignAnalysis }: { scenarioUrls?: ScenarioUrl[]; campaignAnalysis: AnalysisData[] | AnalysisDataWithFailedUrls[] }) => {
  return campaignAnalysis.map((url) => {
    const scenarioLinksToBuild = (scenarioUrls.find((i) => i.url_id === url.targetPageId) || {}).links_to_build || 0;

    return { ...url, scenarioLinksToBuild };
  });
};

const baseAnalysisData: AnalysisDataWithFailedUrls = {
  location: { code: null, name: null, type: null, code_parent: null, country_iso_code: null },
  isEmptyData: true,
  isFailed: true,
  message: '',
  url: '',
  totalVolume: 0,
  targetPage: '',
  campaignRank: 0,
  difficulty: 0,
  pageAuthorityDiff: 0,
  linksRootDomainDiff: 0,
  lrdGap: 0,
  ageAverage: 0,
  scenarioLinksToBuild: 0,
  backlinksTotal: 0,
  domain: 'N/A',
  targetPageId: 0,
  keyword: {
    id: 0,
    text: '',
    text_hash: 'N/A',
    priority: 0,
    excluded: false,
  },
  pageAuthority: 0,
  linksRootDomain: 0,
  rootDomain: 0,
  backlinks: 0,
  contextRelevanceScoreAvg: 0,
  backlinkDADistribution: {
    '1to150': 0,
    '150to300': 0,
    '300to500': 0,
    '500to750': 0,
    '750+': 0,
  },
  total: 0,
  domainAuthorityAvg: 0,
  domainAuthority: 0,
  volumeGapRatio: 0,
  ageAverageDiff: 0,
  velocity: {
    '3months': 0,
    '6months': 0,
  },
  anchorTextBuckets: {
    exactMatch: 0,
    partialMatch: 0,
    other: 0,
  },
  anchorTextBucketsCount: {
    exactMatch: 0,
    partialMatch: 0,
    other: 0,
  },
  competitor: {
    ageAverage: 0,
    pageAuthority: 0,
    linksRootDomain: 0,
    rootDomain: 0,
    domainAuthority: 0,
    contextRelevanceScoreAvg: 0,
    backlinkDADistribution: {
      '1to150': 0,
      '150to300': 0,
      '300to500': 0,
      '500to750': 0,
      '750+': 0,
    },
    total: 0,
    anchorTextBuckets: {
      exactMatch: 0,
      partialMatch: 0,
      other: 0,
    },
    anchorTextBucketsCount: {
      exactMatch: 0,
      partialMatch: 0,
      other: 0,
    },
    domainAuthorityAvg: 0,
    linksOverLrd: 0,
    rank: 0,
    velocity: {
      '3months': 0,
      '6months': 0,
    },
  },
  closeToGap: 0,
  lrdGapKeyword: 0,
  contextRelevanceScoreDiff: 0,
  velocityDiff: {
    '3months': 0,
    '6months': 0,
  },
  avgVolume: 0,
  serpFeaturesByKeyword: [],
};

const createAnalysisData = (overrides: Partial<AnalysisDataWithFailedUrls>): AnalysisDataWithFailedUrls => {
  return { ...JSON.parse(JSON.stringify(baseAnalysisData)), ...overrides };
};

const emptyScenarioUrl: ScenarioUrl = {
  url_id: 0,
  target_page: '',
  close_to_gap: 0,
  links_to_build: 0,
  lrd_gap: 0,
  total_volume: 0,
  anchor_text: {
    exact: 0,
    partial: 0,
    generic: 0,
  },
  defaults: [],
  anchorsTotal: 0,
  is_allocated: false,
};

const createScenarioUrl = (overrides: Partial<ScenarioUrl>): ScenarioUrl => {
  return { ...JSON.parse(JSON.stringify(emptyScenarioUrl)), ...overrides };
};

const getFailedUrlsOverviewTableData = (failedUrls: RunError[]) => {
  return failedUrls.map(({ message, url }) =>
    createAnalysisData({
      message: message || 'Failed to fetch data',
      url: url.url,
      targetPage: url.url,
      targetPageId: url.id,
    }),
  );
};
const getBulkMatchValues = ({ exactPercent, genericPercent, partialPercent }: AnchorTextPercents, total = 0) => {
  const output = {
    exact: { total: 0 },
    partial: { total: 0 },
    generic: { total: 0 },
  };

  if (!total) {
    return output;
  }

  const totalExact = (total * exactPercent) / 100;
  const totalPartial = (total * partialPercent) / 100;
  const totalGeneric = (total * genericPercent) / 100;

  for (const [index] of Array(total).entries()) {
    const group = index % 3;

    if (group === 0 && output.partial.total < totalPartial) {
      output.partial.total++;
      continue;
    }

    if (group === 1 && output.exact.total < totalExact) {
      output.exact.total++;
      continue;
    }

    if (group === 2 && output.generic.total < totalGeneric) {
      output.generic.total++;
      continue;
    }

    if (output.partial.total < totalPartial) {
      output.partial.total++;
      continue;
    }

    if (output.exact.total < totalExact) {
      output.exact.total++;
      continue;
    }

    if (output.generic.total < totalGeneric) {
      output.generic.total++;
      continue;
    }

    output.partial.total++;
  }

  return output;
};

const ERROR_REASONS_MAP: Record<RunErrorType, string> = {
  no_competitors_found: 'No competitors found',
  no_keywords_found: 'No keywords found',
  no_metrics_found: 'No metrics found',
};

const mockKeywordAnalysis = ({ kw, reasons, target_page }: { kw: Keyword; reasons: string[]; target_page: string }): AnalysisDataWithFailedUrls => {
  return createAnalysisData({
    message: reasons.join(','),
    url: target_page,
    targetPage: target_page,
    keyword: kw,
  });
};
export {
  MAP_FIELD_TO_TITLE,
  getScenarioProjectedROI,
  getBudgetValue,
  getParsedAnalysisData,
  getScenarioUrlKeywords,
  redistributeMatches,
  getEstimatedCampaignCost,
  getCampaignAnalysisStep,
  getOverviewTableData,
  getUserSelectionAllocations,
  getBulkMatchValues,
  getFailedUrlsOverviewTableData,
  ERROR_REASONS_MAP,
  mockKeywordAnalysis,
  createScenarioUrl,
};
