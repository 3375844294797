import Modal, { ModalProps } from '@/components/Modal';
import { Button } from '@/components';
import { FC } from 'react';

interface AlreadyExistingCombinationsModalProps extends ModalProps {
  alreadyExistingCombinations: Combination[];
}

const AlreadyExistingCombinationsModal: FC<AlreadyExistingCombinationsModalProps> = ({ isOpen, onClose, alreadyExistingCombinations }) => {
  return (
    <Modal title='Already existing combinations' className='max-w-modal-2xl' isOpen={isOpen} onClose={onClose}>
      <div className='flex flex-col gap-2'>
        <p className='font-semibold'>One or more URLs / Keywords combinations already exist in this campaign:</p>
        <div className='max-h-96 overflow-y-auto'>
          <ol className='list-decimal pl-6'>
            {!alreadyExistingCombinations.length && <p>?</p>}
            {alreadyExistingCombinations.map(({ url, keywords }) => {
              return (
                <li key={url} className='pb-2'>
                  <p className='font-semibold'>{url}</p>
                  <ul className='mt-1 list-disc pl-6'>
                    {keywords.map((i) => (
                      <li key={i}>{i}</li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
      <div className='mt-2 flex'>
        <Button onClick={onClose} isLoading={false} className='ml-auto whitespace-nowrap text-left'>
          Accept
        </Button>
      </div>
    </Modal>
  );
};

export default AlreadyExistingCombinationsModal;
