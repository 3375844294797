import { formatScenario } from '@/utils/scenarios';
import { getCloseToGap, getLinksState, getLinksToBuild } from '@/utils/urls';
import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import campaignAPI from './campaign.api';
import { BASE_API_URL } from '@/constants/application';
import { RootState } from '../store';
import { base62Encode } from '@/utils/baseX';

type StepProps = {
  isDirty: boolean;
};

type CampaignInitialState = {
  countryList: Country[];
  campaign: CampaignJSON | null;
  scenarios: ScenarioState;
  selectedScenario?: number;
  editAllScenarios: boolean;
  campaignAnalysis: AnalysisData[];
  maxGapRatio: number | null;
  steps: Record<AnalysisStep, StepProps>;
  keywords: RefineCampaignKeyword[];
  linksToBuildState: LinksToBuildState;
  closeToGapState: CloseToGapState;
  allocations: AllocationKeyword[];
};

export const getCampaignAnalysisRecommended = createAsyncThunk(
  'campaign/getCampaignAnalysisRecommended',
  async ({ id, categories, scenarioId }: { id: string; scenarioId: number; categories: Array<string> }, { getState, rejectWithValue, dispatch }) => {
    const headers = { 'Content-Type': 'application/json', Authorization: `JWT ${(getState() as RootState).auth.token}` };

    const urlParts = [`${BASE_API_URL}campaigns/${id}/`];

    urlParts.push(scenarioId ? `scenarios/${scenarioId}/recommended/` : 'analysis/recommended/');

    if (categories && categories.length) {
      urlParts.push('?', categories.map((i) => `categories[]=${base62Encode(i)}`).join('&'));
    }

    const response = await fetch(urlParts.join(''), { headers, method: 'GET' });

    if (response.status !== 200) {
      return rejectWithValue(response);
    }

    return await response.json();
  },
);

const initialState: CampaignInitialState = {
  countryList: [],
  scenarios: {},
  selectedScenario: undefined,
  editAllScenarios: false,
  campaign: null,
  campaignAnalysis: [],
  maxGapRatio: null,
  keywords: [],
  steps: {
    analysis: { isDirty: false },
    'scenario-review': { isDirty: false },
    'anchor-text-generator': { isDirty: false },
    summary: { isDirty: false },
  },
  linksToBuildState: {},
  closeToGapState: {},
  allocations: [],
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setScenarios(state, action: PayloadAction<Scenario[]>) {
      for (const scenario of action.payload) {
        const linksState = getLinksState(scenario);

        state.scenarios[scenario.id] = scenario;
        state.linksToBuildState[scenario.id] = linksState.ltbState;
        state.closeToGapState[scenario.id] = linksState.ctgState;
      }
    },
    setEditAllScenarios(state, action: PayloadAction<boolean>) {
      state.editAllScenarios = action.payload;
    },
    updateScenario(state, action: PayloadAction<{ scenario: Scenario; company: Company | null }>) {
      const { scenario, company } = action.payload;
      const formattedScenario = formatScenario(scenario, state.campaignAnalysis, company);
      const linksState = getLinksState(formattedScenario);

      state.scenarios[scenario.id] = formattedScenario;
      state.linksToBuildState[scenario.id] = linksState.ltbState;
      state.closeToGapState[scenario.id] = linksState.ctgState;
    },

    setSelectedScenario(state, action: PayloadAction<number>) {
      const scenariosIds = Object.keys(state.scenarios).map((k) => Number(k)); // transform to Number since Object keys are string default;
      if (!scenariosIds.includes(action.payload)) {
        return;
      }

      const linksState = getLinksState(state.scenarios[action.payload]);
      state.linksToBuildState[action.payload] = linksState.ltbState;
      state.closeToGapState[action.payload] = linksState.ctgState;
      state.selectedScenario = action.payload;
    },
    setIsDirty(state, action: PayloadAction<{ step: AnalysisStep; isDirty: boolean }>) {
      state.steps[action.payload.step].isDirty = action.payload.isDirty;
    },
    updateScenarioUrls(state, action: PayloadAction<{ scenarioId: number; urls: Array<ScenarioUrl>; company: Company | null }>) {
      const scenario = { ...state.scenarios[action.payload.scenarioId], urls: action.payload.urls };
      state.scenarios[action.payload.scenarioId] = formatScenario(scenario as Scenario, state.campaignAnalysis, action.payload.company);
    },

    updateSelectedScenarioUrls(state, action: PayloadAction<{ url: ScenarioUrl; company: Company | null; scenarioId?: number }>) {
      if (!state.selectedScenario) {
        return;
      }

      const targetScenario = action.payload.scenarioId ? (state.scenarios[action.payload.scenarioId] as Scenario) : (state.scenarios[state.selectedScenario] as Scenario);
      const urlIndex = targetScenario.urls.findIndex((url) => url.url_id === action.payload.url.url_id);

      if (urlIndex !== -1) {
        targetScenario.urls.splice(urlIndex, 1);
      } else {
        targetScenario.urls.push(action.payload.url);
      }

      const formatedScenario = formatScenario(targetScenario, state.campaignAnalysis, action.payload.company);
      state.scenarios[targetScenario.id] = formatedScenario;

      const linksState = getLinksState(state.scenarios[targetScenario.id]);
      state.linksToBuildState[targetScenario.id] = linksState.ltbState;
      state.closeToGapState[targetScenario.id] = linksState.ctgState;
    },
    updateAllScenariosUrls(state, action: PayloadAction<{ url: ScenarioUrl }>) {
      const { selectedScenario } = state;
      if (!selectedScenario) {
        return;
      }
      const { url } = action.payload;
      state.steps.analysis.isDirty = true;

      const scenarios = Object.values(state.scenarios);

      const isUrlInSelectedScenario = state.scenarios[selectedScenario].urls.some((u) => u.url_id === url.url_id);

      for (const scenario of scenarios) {
        if (isUrlInSelectedScenario) {
          scenario.urls = scenario.urls.filter((u) => u.url_id !== url.url_id);
        } else {
          scenario.urls.push(url);
        }

        const linksState = getLinksState(state.scenarios[scenario.id]);
        state.linksToBuildState[scenario.id] = linksState.ltbState;
        state.closeToGapState[scenario.id] = linksState.ctgState;
      }
    },

    setUrlLinksToBuild(state, action: PayloadAction<{ url_id: number; links_to_build: number; company: Company | null; scenarioId?: number }>) {
      const { url_id, links_to_build, company } = action.payload;
      if (!state.selectedScenario) {
        return;
      }

      const targetScenario = action.payload.scenarioId ? (state.scenarios[action.payload.scenarioId] as Scenario) : (state.scenarios[state.selectedScenario] as Scenario);
      const editedScenarioUrls = targetScenario.urls.map((url) => {
        if (url.url_id !== url_id) return url;

        const close_to_gap = getCloseToGap({
          linksToBuild: links_to_build,
          lrdGap: url.lrd_gap,
          projLength: state.scenarios[targetScenario.id].proj_length,
        });

        // Update the linksToBuildState & closeToGapState so that the input fields are updated
        state.linksToBuildState[targetScenario.id][url.url_id] = links_to_build.toString();
        state.closeToGapState[targetScenario.id][url.url_id] = close_to_gap || '';

        return { ...url, close_to_gap: Number(close_to_gap), links_to_build };
      });

      // Update the scenario with the new urls & set the selected scenario to the updated scenario
      state.scenarios[targetScenario.id] = formatScenario({ ...targetScenario, urls: editedScenarioUrls } as Scenario, state.campaignAnalysis, company);
    },

    setEveryScenarioUrlLinksToBuild(state, action: PayloadAction<{ url?: ScenarioUrl; url_id: number; links_to_build: number; company: Company | null }>) {
      const { selectedScenario } = state;
      const { links_to_build, url_id, company } = action.payload;
      if (!selectedScenario) return;

      const scenarios = Object.values(state.scenarios);

      for (const scenario of scenarios) {
        const isUrlInScenario = scenario.urls.some((url) => url.url_id === url_id);
        if (!isUrlInScenario && action.payload.url) {
          scenario.urls = [...scenario.urls, action.payload.url];
        }
        const scenarioUrls = scenario.urls.map((url) => {
          if (url.url_id !== url_id) return url;

          const closeToGap = getCloseToGap({ linksToBuild: links_to_build, lrdGap: url.lrd_gap, projLength: scenario.proj_length }) || '';
          return { ...url, links_to_build, close_to_gap: Number(closeToGap) };
        });

        state.scenarios[scenario.id!] = formatScenario({ ...scenario, urls: scenarioUrls } as Scenario, state.campaignAnalysis, company);

        const targetScenario = state.scenarios[scenario.id];
        const urlIdx = targetScenario.urls.findIndex((url) => url.url_id === url_id);

        state.linksToBuildState[scenario.id][url_id] = links_to_build.toString();
        state.closeToGapState[scenario.id][url_id] =
          getCloseToGap({
            linksToBuild: links_to_build,
            lrdGap: targetScenario.urls[urlIdx].lrd_gap,
            projLength: targetScenario.proj_length,
          }) || '';
      }
    },

    setUrlCloseToGap(state, action: PayloadAction<{ url_id: number; close_to_gap: number; company: Company | null; scenarioId?: number }>) {
      const { campaignAnalysis } = state;
      const { url_id, close_to_gap, company } = action.payload;
      if (!state.selectedScenario) {
        return;
      }

      const targetScenario = action.payload.scenarioId ? (state.scenarios[action.payload.scenarioId] as Scenario) : (state.scenarios[state.selectedScenario] as Scenario);
      const editedScenarioUrls = targetScenario.urls.map((url) => {
        if (url.url_id !== url_id) return url;

        const links_to_build = getLinksToBuild({
          closeToGap: close_to_gap,
          lrdGap: url.lrd_gap,
          projLength: targetScenario.proj_length,
        });

        // Update the linksToBuildState & closeToGapState so that the input fields are updated
        state.linksToBuildState[targetScenario.id][url.url_id] = links_to_build;
        state.closeToGapState[targetScenario.id][url.url_id] = close_to_gap.toString();

        return { ...url, close_to_gap: close_to_gap, links_to_build: Number(links_to_build) };
      });

      // Update the scenario with the new urls & set the selected scenario to the updated scenario
      state.scenarios[targetScenario.id] = formatScenario({ ...targetScenario, urls: editedScenarioUrls } as Scenario, campaignAnalysis, company);
    },

    setEveryScenarioUrlCloseToGap(state, action: PayloadAction<{ url_id: number; close_to_gap: number; company: Company | null; url?: ScenarioUrl }>) {
      const { selectedScenario } = state;
      const { close_to_gap, url_id, company } = action.payload;
      if (!selectedScenario) return;

      for (const scenario of Object.values(state.scenarios)) {
        const isUrlInScenario = scenario.urls.some((url) => url.url_id === url_id);
        if (!isUrlInScenario && action.payload.url) {
          scenario.urls = [...scenario.urls, action.payload.url];
        }
        const scenarioUrls = scenario.urls.map((url) => {
          if (url.url_id !== url_id) return url;

          const links_to_build = getLinksToBuild({ closeToGap: close_to_gap, lrdGap: url.lrd_gap, projLength: scenario.proj_length }) || '';
          return { ...url, close_to_gap, links_to_build: Number(links_to_build) };
        });

        state.scenarios[scenario.id!] = formatScenario({ ...scenario, urls: scenarioUrls } as Scenario, state.campaignAnalysis, company);

        const targetScenario = state.scenarios[scenario.id];

        const urlIdx = targetScenario.urls.findIndex((url) => url.url_id === url_id);
        state.linksToBuildState[scenario.id][url_id] = getLinksToBuild({
          closeToGap: close_to_gap,
          lrdGap: targetScenario.urls[urlIdx].lrd_gap,
          projLength: targetScenario.proj_length,
        });
        state.closeToGapState[scenario.id][url_id] = close_to_gap.toString();
      }
    },

    setKeywords(state, action: PayloadAction<RefineCampaignKeyword[]>) {
      state.keywords = action.payload;
    },
    updateKeyword(state, action: PayloadAction<RefineCampaignKeyword>) {
      const index = state.keywords.findIndex((keyword) => keyword.id === action.payload.id);

      if (index !== -1) {
        state.keywords[index] = action.payload;
      }
    },
    setApprovedScenario(state, action: PayloadAction<{ scenarioId: number }>) {
      const { scenarioId } = action.payload;
      for (const scenario of Object.values(state.scenarios)) {
        if (scenario.id === scenarioId) {
          scenario.is_approved = !scenario.is_approved;
          continue;
        }

        scenario.is_approved = false;
      }
    },
    setAllocations(state, action: PayloadAction<AllocationKeyword[]>) {
      state.allocations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaignAnalysisRecommended.fulfilled, (state, action) => {});
    builder.addMatcher(campaignAPI.endpoints.getCountries.matchFulfilled, (state, { payload }) => {
      state.countryList = payload.results.filter((country) => !country.key.includes('mobile'));
    });
    builder.addMatcher(campaignAPI.endpoints.getCampaignAnalysis.matchFulfilled, (state, { payload }) => {
      state.campaignAnalysis = payload.results;
      state.maxGapRatio = payload.maxGapRatio;
    });
    builder.addMatcher(campaignAPI.endpoints.getCampaign.matchFulfilled, (state, { payload }) => {
      state.campaign = payload;
    });
    builder.addMatcher(campaignAPI.endpoints.getCampaignScenarios.matchFulfilled, (state, { payload }) => {
      state.scenarios = {}; // clean-up state before updating
      let selectedScenario = payload.results.at(-1)?.id;
      for (const scenario of payload.results) {
        if (scenario.is_approved) {
          selectedScenario = scenario.id;
        }

        state.scenarios[scenario.id] = scenario;

        const linksState = getLinksState(scenario);
        state.linksToBuildState[scenario.id] = linksState.ltbState;
        state.closeToGapState[scenario.id] = linksState.ctgState;
      }

      state.selectedScenario = selectedScenario;
    });
    builder.addMatcher(campaignAPI.endpoints.getRefineKeywords.matchFulfilled, (state, { payload, meta }) => {
      const hasParams = meta.arg.originalArgs.excluded_domains || meta.arg.originalArgs.excluded_keywords || meta.arg.originalArgs.url_id;

      if (!hasParams) {
        state.keywords = payload.results;
      }
    });

    builder.addMatcher(campaignAPI.endpoints.createCampaignScenario.matchFulfilled, (state, { payload }) => {
      state.scenarios[payload.data.id] = payload.data;
      state.selectedScenario = payload.data.id;

      const linksState = getLinksState(payload.data);
      state.linksToBuildState[payload.data.id] = linksState.ltbState;
      state.closeToGapState[payload.data.id] = linksState.ctgState;
    });

    builder.addMatcher(campaignAPI.endpoints.deleteCampaignScenario.matchFulfilled, (state, { meta }) => {
      const scenarioIdToRemove = meta.arg.originalArgs.scenario.id;

      if (!scenarioIdToRemove) {
        return;
      }

      delete state.scenarios[scenarioIdToRemove];
      const lastScenario = Object.keys(state.scenarios).at(-1);

      if (lastScenario) {
        state.selectedScenario = Number(lastScenario);
      }
    });
  },
});

export const {
  setScenarios,
  setEditAllScenarios,
  setIsDirty,
  setSelectedScenario,
  updateScenario,
  updateScenarioUrls,
  setKeywords,
  updateKeyword,

  updateSelectedScenarioUrls,
  updateAllScenariosUrls,

  setUrlLinksToBuild,
  setEveryScenarioUrlLinksToBuild,

  setUrlCloseToGap,
  setEveryScenarioUrlCloseToGap,

  setApprovedScenario,
  setAllocations,
} = campaignSlice.actions;

export default campaignSlice;
