import { Tabs } from '@/components';
import { useGetAnchorTextBatchesQuery, useLazyGetAnchorTextBatchesQuery, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { selectScenarios } from '@/store/campaign/campaign.selector';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import { getStateItemUpdater } from '@/utils/arrayStateItem';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AnchorTextsTable from '../AnchorTextsTable';
import UrlAnchorTextTable from '../UrlAnchorTextTable';
import { useAnchorTextNotification } from '@/hooks/websocket';

type AnchorTextResultsTabsProps = {
  isLoading?: boolean;
};

const AnchorTextResultsTabs: FC<AnchorTextResultsTabsProps> = ({ isLoading = false }) => {
  const { campaignId, batchId } = useParams() as { campaignId: string; batchId: string };
  const dispatch = useDispatch<AppDispatch>();
  const scenarios = useSelector(selectScenarios);

  const [saveCampaignConfig] = useSaveCampaignConfigMutation();

  const [urlAnchorTexts, setUrlAnchorTexts] = useState<UrlAnchorText[]>([]);
  const [isAnchorsLoading, setIsAnchorsLoading] = useState(false);
  const { notification } = useAnchorTextNotification();
  const isFetchInProgress = useMemo(() => isLoading || isAnchorsLoading, [isLoading, isAnchorsLoading]);

  const [getBatches] = useLazyGetAnchorTextBatchesQuery();

  const scenario = useMemo(() => scenarios.find(({ is_approved }) => is_approved), [scenarios]);

  const { data } = useGetAnchorTextBatchesQuery({ campaignId, pageSize: 500 });

  const results: UrlAnchorTextData[] = useMemo(() => data?.results.find((b) => b.id === Number(batchId))?.urls || [], [data, batchId]);

  const fetchAnchorTexts = useCallback(async () => {
    setIsAnchorsLoading(true);
    const { results: batches } = await getBatches({ campaignId, pageSize: 500 }).unwrap();

    const currentBatch = batches.find((b) => b.id === Number(batchId));
    if (!currentBatch) {
      setIsAnchorsLoading(false);
      return [];
    }

    const newAnchorTexts = currentBatch.anchor_texts;

    const anchorTexts = newAnchorTexts.map((x) => x).sort((a, b) => a.id - b.id);

    setUrlAnchorTexts(anchorTexts);

    await saveCampaignConfig({ campaignId: campaignId, config: { has_anchor_texts: anchorTexts.length > 0 } }).unwrap();
    setIsAnchorsLoading(false);
  }, [campaignId, saveCampaignConfig, batchId, getBatches]);

  useEffect(() => {
    if (!isLoading || notification?.action === 'generate_anchors_task_done') {
      fetchAnchorTexts();
    }
  }, [isLoading, notification, fetchAnchorTexts]);

  const handleUpdateUrlAnchorText = useCallback(
    (url: UrlAnchorText) => {
      dispatch(setIsDirty({ step: 'anchor-text-generator', isDirty: true }));
      setUrlAnchorTexts(getStateItemUpdater(url, (a, b) => a.id === b.id));
    },
    [dispatch],
  );

  const tabs: Array<TabsContent> = [
    {
      label: 'URLs',
      content: (
        <UrlAnchorTextTable
          urlAnchorsTextData={results}
          urlAnchorTexts={urlAnchorTexts}
          isLoading={isFetchInProgress}
          updateUrlAnchorTexts={handleUpdateUrlAnchorText}
          scenario={scenario}
        />
      ),
    },
    {
      label: 'Anchor Text',
      content: <AnchorTextsTable urlAnchorTexts={urlAnchorTexts} isLoading={isFetchInProgress} updateUrlAnchorTexts={handleUpdateUrlAnchorText} />,
      disabled: urlAnchorTexts.length === 0,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default AnchorTextResultsTabs;
