import { Button } from '@/components';
import { AnchorTextBatchTable } from '@/pages/Campaigns/pages/AnchorTextBatchDashboard/components';
import { useDownloadScenarioExportCsvMutation } from '@/store/campaign/campaign.api';
import { ArrowDownTrayIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

interface AnchorTextOVerviewTableProps {
  isLoading: boolean;
}

const AnchorTextOVerviewTable: FC<AnchorTextOVerviewTableProps> = ({ isLoading }) => {
  const { campaignId } = useParams() as { campaignId: string };
  const [downloadCSV, { isLoading: isCSVLoading }] = useDownloadScenarioExportCsvMutation();

  const handleDownloadCsv = async () => {
    await downloadCSV({ campaignId: campaignId });
  };

  return (
    <div className='flex w-full flex-col  rounded-leap bg-white p-5 text-left text-gray-800 shadow-md'>
      <div className='flex flex-row items-center justify-between'>
        <p className='text-right text-xl leading-8'>Anchor Text Overview</p>
        <Button variant='outline-light' className='flex flex-nowrap items-center gap-2' onClick={handleDownloadCsv} disabled={isCSVLoading}>
          {isCSVLoading ? <ArrowPathIcon className='w-5 animate-spin text-gray-700' /> : <ArrowDownTrayIcon className='w-5 text-gray-700' />}
          Export CSV
        </Button>
      </div>
      <AnchorTextBatchTable withCheckbox={false} withSearch={false} isLoading={isLoading} className='max-h-[180px] overflow-scroll' />
    </div>
  );
};

export default AnchorTextOVerviewTable;
