const KEYS = ['target_audiences', 'purchase_stakeholders', 'stakeholder_questions'] as const;

type Key = typeof KEYS[number];

type Tree = URLTargetAudience[] | PurchaseStakeholder[] | StakeholderQuestion[];

type FormattedTree = {
  id: number;
  checked: boolean;
  path: string;
  children: FormattedTree[] | null;
};

const KEY_MAP = KEYS.reduce((accum, current, index) => {
  return { ...accum, [index]: current };
}, {} as Record<number, Key>);

const formatTree = (target: Tree, accPath: string): FormattedTree[] => {
  return target.map((t) => {
    const path = [accPath, t.id].filter(Boolean).join('-');

    // Find the key that matches the KEYS
    const matchedKey = Object.keys(t).find((k) => KEYS.includes(k as Key)) as Key | undefined;

    if (!matchedKey) {
      return {
        id: t.id,
        checked: false,
        path,
        children: null,
      };
    }

    // Type-safe access
    const next = t[matchedKey as keyof typeof t] as unknown as Tree;

    return {
      id: t.id,
      checked: false,
      children: next ? formatTree(next, path) : null,
      path,
    };
  });
};

const getInitialResults = () => KEYS.reduce((accum, current) => ({ ...accum, [current]: [] }), {} as Record<Key, number[]>);

export { KEY_MAP, formatTree, getInitialResults, type FormattedTree, type Tree, type Key };
