import { SearchBarDebounce, VirtualizedTable } from '@/components';
import { useTable } from '@/hooks/table';
import useAnchorTextBatchColumns from './anchorTextBatchTableColumns';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FilterFnOption, Row } from '@tanstack/react-table';
import AnchorTextBatchTableFooter from './AnchorTextBatchTableFooter';
import { useGetAnchorTextBatchesQuery } from '@/store/campaign/campaign.api';
import { useParams } from 'react-router-dom';
import cn from '@/utils/style';

const titleFilter = (
  row: Row<AnchorTextBatch & { totals: { match_type: { exact: number; generic: number; partial: number } } }>,
  _columnId: keyof AnchorTextBatch,
  filterValue: string,
) => {
  return Boolean(row.original.title?.toLowerCase().includes(filterValue.toLowerCase()));
};

interface BaseProps {
  isLoading: boolean;
  withSearch?: boolean;
  className?: string;
}

type AnchorBatchTableWithChecks = {
  withCheckbox: true;
  onCheck: Dispatch<SetStateAction<Record<string, boolean>>>;
  checked: Record<string, boolean>;
};

type AnchorBatchTableWithoutChecks = {
  withCheckbox?: false;
  onCheck?: never;
  checked?: never;
};

type AnchorTextBatchTableProps = BaseProps & (AnchorBatchTableWithChecks | AnchorBatchTableWithoutChecks);

const INITIAL_TOTALS_MATCH_VALUE = {
  exact_match: 0,
  generic_match: 0,
  partial_match: 0,
};

const AnchorTextBatchTable = ({ onCheck, checked, isLoading, className, withSearch = true, withCheckbox = true }: AnchorTextBatchTableProps) => {
  const { campaignId } = useParams() as { campaignId: string };
  const columns = useAnchorTextBatchColumns();
  const [search, setSearch] = useState('');
  const { data: textBatchesList } = useGetAnchorTextBatchesQuery({ campaignId, pageSize: 500 });

  const tableData = useMemo(() => {
    if (!textBatchesList) {
      return [];
    }

    return textBatchesList.results.map((batch) => {
      const totals = batch.urls?.reduce(
        (acc, url) => {
          acc.exact_match += url.exact_match;
          acc.generic_match += url.generic_match;
          acc.partial_match += url.partial_match;
          return acc;
        },
        { ...INITIAL_TOTALS_MATCH_VALUE },
      ) || { ...INITIAL_TOTALS_MATCH_VALUE };

      return {
        ...batch,
        totals: {
          match_type: {
            exact: totals?.exact_match || 0,
            generic: totals?.generic_match || 0,
            partial: totals?.partial_match || 0,
          },
        },
      };
    });
  }, [textBatchesList]);

  const [table] = useTable({
    data: tableData,
    columns,
    tableCustomOptions: ['allowFilters'],
    tableOptions: {
      state: {
        globalFilter: search,
        rowSelection: checked,
        columnVisibility: {
          checked: withCheckbox,
        },
      },
      getRowId: (row) => row.id.toString(),
      onRowSelectionChange: onCheck,
      onGlobalFilterChange: setSearch,
      globalFilterFn: titleFilter as FilterFnOption<AnchorTextBatch & { totals: { match_type: { exact: number; generic: number; partial: number } } }>,
    },
  });

  return (
    <div className='mt-4 space-y-4 rounded-leap bg-white p-4'>
      {withSearch && <SearchBarDebounce onChange={(val) => setSearch(val)} delay={300} inputClassName='w-[25.75rem] h-10' />}
      <VirtualizedTable
        isLoading={isLoading}
        table={table}
        tableFooter={<AnchorTextBatchTableFooter withCheckbox={withCheckbox} data={textBatchesList?.anchor_text_totals || null} />}
        name='anchorTextBatch'
        containerClass={cn('max-h-[60dvh]', className)}
        loadingText={'Loading batches...'}
        cellStyle='py-4 text-slate-800 text-sm'
      />
    </div>
  );
};

export default AnchorTextBatchTable;
