import { TruncatedTextCell } from '@/components';
import { BASE_API_URL } from '@/constants/application';
import { selectAuthToken, selectAuthUser } from '@/store/auth/auth.selector';
import cn from '@/utils/style';
import { ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/24/solid';
import { createColumnHelper, Row, Table } from '@tanstack/react-table';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

const columnHelper = createColumnHelper<LIRReport>();

const handleDownload = async (row: Row<LIRReport>, authToken: string | null) => {
  const response = await fetch(`${BASE_API_URL}reports/${row.original.id}/download/`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = blobUrl;
  a.download = row.original.name;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const BASE_URL = `${BASE_API_URL?.replace('/v1/', '')}`;

type Meta = {
  setCurrentReport: (report: LIRReport) => void;
  toggleDeleteModal: (value: boolean) => void;
  rowsMap: Record<number, boolean>;
  setRowsMap: Dispatch<SetStateAction<Record<number, boolean>>>;
};

const useReportsTableColumns = () => {
  const authUser = useSelector(selectAuthUser);
  const authToken = useSelector(selectAuthToken);
  const userId = authUser?.id;

  const handleReportDelete = useCallback((table: Table<LIRReport>, row: Row<LIRReport>) => {
    const tableMeta = table.options.meta as Meta;

    tableMeta.setCurrentReport(row.original);
    tableMeta.toggleDeleteModal(true);
  }, []);

  return useMemo(
    () => [
      columnHelper.accessor('name', {
        header: () => <p className='text-start'>Campaign Report</p>,
        cell: ({ row }) => {
          return (
            <button onClick={() => handleDownload(row, authToken)} className={cn('line-clamp-1 cursor-pointer text-start  text-blue-500')}>
              {row.original.name}
            </button>
          );
        },
      }),
      columnHelper.accessor('description', {
        header: () => <p className='text-start'>Description</p>,
        cell: ({ row, table, getValue }) => (
          <TruncatedTextCell row={row} table={table}>
            {getValue()}
          </TruncatedTextCell>
        ),
        size: 300,
      }),
      columnHelper.accessor('created_by', {
        header: () => <p className='text-start'>Added By</p>,
        cell: ({ row }) => {
          const profilePictureUrl = row.original.created_by?.profile_picture_url;
          return (
            <div className='flex items-center gap-4 text-start'>
              {profilePictureUrl && (
                <div className={cn('flex h-8 w-8 items-center justify-center overflow-hidden rounded-full text-xl font-bold text-white')}>
                  <img src={`${BASE_URL}${profilePictureUrl}`} alt='avatar' className='min-h-full rounded-full' />
                </div>
              )}
              {row.original.created_by?.email}
            </div>
          );
        },
      }),
      columnHelper.accessor('companies.id', {
        header: () => <p className='text-start'>Companies</p>,
        cell: ({ row, table }) => {
          const isExpanded = (table.options.meta as Meta).rowsMap[row.original.id];
          const companiesLength = row.original.companies.length;
          return (
            <>
              <p className='text-start'>
                {isExpanded &&
                  row.original.companies.map((company) => (
                    <span key={company.id} className='block'>
                      {company.name}
                    </span>
                  ))}
                {!isExpanded && row.original.companies[0].name}
              </p>
              {companiesLength > 1 && (
                <button
                  className='w-full text-start text-blue-500'
                  onClick={() => (table.options.meta as Meta).setRowsMap((prev) => ({ ...prev, [row.original.id]: !prev[row.original.id] }))}
                >
                  {!isExpanded ? 'View More' : 'View Less'}
                </button>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor('created_at', {
        header: () => <p className='text-start'>Date Added</p>,
        cell: ({ row }) => <p className='text-start'>{new Date(row.original.created_at).toDateString()}</p>,
      }),
      columnHelper.display({
        size: 1,
        id: 'deleteAction',
        cell: ({ table, row }) => {
          const canDelete = row.original.created_by?.id === userId;
          return (
            <button disabled={!canDelete} onClick={() => handleReportDelete(table, row)}>
              <TrashIcon className='size-5 text-gray-400 duration-200 hover:text-gray-600' />
            </button>
          );
        },
      }),
      columnHelper.display({
        size: 1,
        id: 'downloadAction',
        cell: ({ row }) => {
          return (
            <button onClick={() => handleDownload(row, authToken)}>
              <ArrowDownTrayIcon className='size-5 text-gray-400 duration-200 hover:text-gray-600' />
            </button>
          );
        },
      }),
    ],
    [userId, handleReportDelete, authToken],
  );
};
export default useReportsTableColumns;
