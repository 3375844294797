import { Button, ErrorMessage } from '@/components';
import { CombinationsField } from '@/components/AddUrlCombinationManuallyModal';
import { useAddCampaignUrlMutation, useLazyGetCampaignQuery, useGetCampaignQuery } from '@/store/campaign/campaign.api';
import { AppDispatch } from '@/store/store';
import { useField } from 'formik';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useCampaignsNotifications } from '@/hooks/websocket';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import api from '@/store/api';
import { parseCombinations } from '@/utils/urls';
import { AlreadyExistingCombinationsModal } from '../AlreadyExistingCombinationsModal';
import { useToggle } from '@/hooks/toggle';

type CombinationWithSwitchMap = Record<number, Combination & { toggled: boolean }>;

const AddUrlCombinationURLsManually = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { campaignId } = useParams() as { campaignId: string };
  const { data: campaignData } = useGetCampaignQuery({ campaignId, include: ['country'] });

  const dispatch = useDispatch<AppDispatch>();

  const [{ value }] = useField<string>('urls_list');
  const [{ value: combinations }, , { setValue }] = useField<Combination[]>('combinations');

  const [fetchCampaign] = useLazyGetCampaignQuery();
  const { connectionStatus } = useCampaignsNotifications();

  const [hasError, setHasError] = useState(false);
  const [isUpdatingCampaign, setIsUpdatingCampaign] = useState(false);
  const [fetchError, setFetchError] = useState('');
  const [urlKwCombinations, setUrlKwCombinations] = useState<Combination[]>([]);
  const [isTaskDispatched, setIsTaskDispatched] = useState(false);

  const { value: isAlreadyExistingCombinationModalOpen, toggle: toggleAlreadyExistingCombinationModal } = useToggle();

  const [combinationsWithSwitch, setCombinationsWithSwitch] = useState<CombinationWithSwitchMap>(
    combinations.reduce((acc, combination, index) => {
      return { ...acc, [index]: { ...combination, toggled: false } };
    }, {}) || {},
  );

  const navigateBack = () => {
    if (location.pathname.split('/').at(-2) === 'add-url') {
      return navigate('../../');
    }

    return navigate('../');
  };

  const alreadyExistingCombinationsModalOnClose = useCallback(
    (isTaskDispatched = false) => {
      toggleAlreadyExistingCombinationModal(false);
      if (isTaskDispatched) {
        navigateBack();
      }
    },
    [navigateBack, toggleAlreadyExistingCombinationModal],
  );

  const handleCombinationWithSwitch = useCallback((callback: (prev: CombinationWithSwitchMap) => CombinationWithSwitchMap) => {
    setCombinationsWithSwitch(callback);
  }, []);

  const initialCombinations = useMemo(() => {
    return value.split(',').map((url) => {
      return {
        url,
        keywords: [],
        location_code: null,
      };
    });
  }, [value]);

  const [addUrl, { isLoading, isError }] = useAddCampaignUrlMutation();

  const fetchCampaignStatusManually = useCallback(async () => {
    const resp = await fetchCampaign({ campaignId }).unwrap();

    if (resp.last_run?.status === 'error') {
      setIsUpdatingCampaign(false);
      setFetchError('Failed to fetch new URL data');
    }
  }, [campaignId, fetchCampaign]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (isUpdatingCampaign) {
        await fetchCampaignStatusManually();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isUpdatingCampaign, connectionStatus, fetchCampaignStatusManually]);

  const hasEmptyCombinations = useMemo(() => {
    return combinations.some((c, i) => {
      const hasValidUrl = c.url.trim() !== '';
      const hasKeywords = c.keywords.length > 0;
      const isToggledValid = combinationsWithSwitch[i]?.toggled;

      return hasValidUrl && (isToggledValid || hasKeywords);
    });
  }, [combinations, combinationsWithSwitch]);

  const handleOnError = useCallback((hasError: boolean) => {
    setHasError(hasError);
  }, []);

  const handleAddUrl = async () => {
    setIsTaskDispatched(false);

    const { status, response } = await addUrl({ id: campaignId, combinations }).unwrap();

    const combinationsIntersection = combinations
      .map((combination) => {
        const url = response.results.find(({ url }) => url === combination.url);

        if (!url) {
          return null;
        }

        const output = { url: url.url, location_code: url.location_code };

        if (!combination.keywords.length && !url.is_new && url.keywords.every((i) => !i.is_new)) {
          return { ...output, keywords: [] };
        }

        const keywords = url.keywords.filter(({ is_new, text }) => combination.keywords.includes(text) && !is_new);

        if (!keywords.length) {
          return null;
        }

        return { ...output, keywords: keywords.map((i) => i.text) };
      })
      .filter(Boolean) as Combination[];

    if (status === 201) {
      dispatch(setIsDirty({ step: 'analysis', isDirty: true }));
      setIsUpdatingCampaign(true);
      dispatch(api.util.invalidateTags(['campaignAnalysis', 'campaignScenarios', 'campaign']));
      setValue([{ url: '', keywords: [], location_code: null }]);
      setIsTaskDispatched(true);

      if (combinationsIntersection.length > 0) {
        setUrlKwCombinations(combinationsIntersection);
        toggleAlreadyExistingCombinationModal(true);
        return true;
      }

      navigateBack();

      return true;
    }

    if (status === 200) {
      setUrlKwCombinations(combinationsIntersection);
      toggleAlreadyExistingCombinationModal(true);

      return true;
    }

    setHasError(true);
  };

  return (
    <Fragment>
      {isAlreadyExistingCombinationModalOpen && (
        <AlreadyExistingCombinationsModal
          isOpen={isAlreadyExistingCombinationModalOpen}
          onClose={() => alreadyExistingCombinationsModalOnClose(isTaskDispatched)}
          alreadyExistingCombinations={urlKwCombinations}
        />
      )}
      {isError || (fetchError && <ErrorMessage className='pb-4'> {fetchError ? fetchError : 'Something went wrong'}</ErrorMessage>)}
      <CombinationsField
        name='combinations'
        initialCombinations={initialCombinations}
        hasError={hasError}
        onError={handleOnError}
        handleCombinationWithSwitch={handleCombinationWithSwitch}
        combinationsWithSwitch={combinationsWithSwitch}
        country={campaignData?.country}
        isLocalEnabled
      />
      <div className='my-2 flex justify-between gap-3'>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          className='w-1/2'
          variant='outline-light'
        >
          Cancel
        </Button>
        <Button
          className='w-1/2'
          onClick={handleAddUrl}
          disabled={isLoading || isUpdatingCampaign || !parseCombinations(combinations).length || hasError || !hasEmptyCombinations}
          isLoading={isLoading || isUpdatingCampaign}
        >
          Add URL
        </Button>
      </div>
    </Fragment>
  );
};

export default AddUrlCombinationURLsManually;
